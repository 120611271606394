import React, { useContext } from "react";
import styles from "../../styles/NavbarCartItemsCounter.module.scss";
import CartContext from "../../providers/CartContext";

export const NavbarCartItemsCounter: React.FunctionComponent<{}> = ({ }) => {
    const cartContext = useContext(CartContext);

    const getCartItemsCount = () => {
        let count = 0;
        cartContext.cart.cart_items.forEach(cartItem => {
            count += cartItem.quantity;
        });
        return count;
    }
    
    return (
        <React.Fragment>
            {cartContext.cart != null && cartContext.cart.cart_items.length > 0 &&
                    <div className={styles[`navbar-cart-items-counter`]}>
                        <div className={styles[`navbar-cart-items-counter-text`]}>
                            {getCartItemsCount()}
                        </div>
                    </div>
            }
        </React.Fragment>
    )
}