import { rejects } from "assert";

export const storeRepository = {
    getStoreMap: async () => {
        try {
            const url = `${process.env.REACT_APP_STORE_S3_BUCKET}/${process.env.REACT_APP_STORE_JSON_FOLDER}/${process.env.REACT_APP_STORE_MAP_FILENAME}`;

            const response = await fetch(url);

            const storeMap = await response.json();

            return storeMap;
        } catch (error) {
            rejects(error);
        }
    },

    getStoreJSON: async (storeId: string, locale: string) => {
        try {
            const url = `${process.env.REACT_APP_STORE_S3_BUCKET}/${process.env.REACT_APP_STORE_JSON_FOLDER}/${storeId}-${locale}.json`;

            const response = await fetch(url);

            const storeJson = await response.json();

            return {
                storeJson: storeJson,
                error: null
            }
        } catch (error) {
            return {
                storeJson: null,
                error: error.message
            }
        }
    },

    getShippingFormJSON: async () => {
        try{
            const url = `${process.env.REACT_APP_STORE_S3_BUCKET}/${process.env.REACT_APP_STORE_JSON_FOLDER}/shipping-form.json`

            const response = await fetch(url);

            const shippingFormJson = await response.json();

            return shippingFormJson;


        } catch (error) {

            rejects(error);
        }
    }
}