// local-storage-parameter-map.ts
// Author : Matthew Tinn
// Date : 18/08/2021
// Updated on: 19/05/2022
// Now exports 2 objects used for the local storage item names and for the query parameter names

// Map for the names of the local storage items 
export const localStorageItemMap = {
    cart: "rlcyo2_cart", // Cart data for the cart context
    store: "rlcyo2_store", // Store data from the store map JSON
    devModeValidated: "rlcyo2_devModeValidated", // Turn on/off dev password
    dataApiUrl: "rlcyo2_dataApiUrl", // Override for the data API
    imageApiUrl: "rlcyo2_imageApiUrl", // Override for the image API
    kiosk: "rlcyo2_kiosk", // Kiosk mode
    locale: "rlcyo2_locale", // Chosen locale
    disableLangChange: "rlcyo2_disableLangChange", // Remove language change from nav
    forcePNG: "rlcyo2_forcePNG", // PNG images
    debug: "rlcyo2_debug", // Enable/disable debug mode
    mode: "rlcyo2_mode", // Entry mode into configurator from homepage
    state: "rlcyo2_state", // Used to enter direct to configure mode
    CCO: "rlcyo2_CCO", // CCO to load
    quickAdd: "rlcyo2_quickAdd", // Enables quick add on checkout
    localPrint: "rlcyo2_localPrint", // Enables print receipt functionality
    barcodeFormat: "rlcyo2_barcodeFormat", // Sets default barcode format
    collectionNote: "rlcyo2_collectionNote", // Enables collection note on checkout
    confirmedOrders: "rlcyo2_confirmedOrders", // Bypass print manager on new order
    orderId: "rlcyo2_orderId", // Order ID for confirmation screen,
    disableStorePickup: "rlcyo2_disableStorePickup", // Disables COLLECTION on checkout
    productThumbnails: "rlcyo2_productThumbnails", // Used to store the pair of product thumbnails for use in the cart, when adding an item to the cart
    iFrame: "rlcyo2_iFrame", // Used to enable/disable iFrame mode
    sessionHash: "rlcyo2_sessionHash", // Used to track a single customer flow
}

// Map for the names of the query parameters, used to map the query parameters to the query parameters object
// used to retrieve the values of the parameters
export const queryParameterMap = {
    store: "store", // Store data from the store map JSON
    devModeValidated: "devModeValidated", // Turn on/off dev password
    dataApiUrl: "dataApiUrl", // Override for the data API
    imageApiUrl: "imageApiUrl", // Override for the image API
    kiosk: "kiosk", // Kiosk mode
    locale: "locale", // Chosen locale
    disableLangChange: "disableLangChange", // Remove language change from nav
    forcePNG: "forcePNG", // PNG images
    debug: "debug", // Enable/disable debug mode
    mode: "mode", // Entry mode into configurator from homepage
    state: "state", // Used to enter direct to configure mode
    CCO: "CCO", // CCO to load
    quickAdd: "quickAdd", // Enables quick add on checkout
    localPrint: "localPrint", // Enables print receipt functionality
    barcodeFormat: "barcodeFormat", // Sets default barcode format
    collectionNote: "collectionNote", // Enables collection note on checkout
    confirmedOrders: "confirmedOrders", // Bypass print manager on new order
    orderId: "orderId", // Order ID for confirmation screen,
    disableStorePickup: "disableStorePickup", // Disables COLLECTION on checkout
    iFrame: "iFrame", // Used to enable/disable iFrame mode
    terminalId: "terminalId" // Used to determine which terminal of the store is the user engaging with the app
}
