import React from "react";
import { updateCartRecipesWithCustomisationNames } from "../helpers/basket/update-cart-recipes-with-customisation-names";
import { localStorageItemMap } from "../helpers/local-storage-parameter-map";
import { getProductDetails } from "../helpers/product-page/get-product-data";

export const addCartItem = (cartState: any, setCartState: any, cart_item_id: string, recipe_id: string, quantity: number, product: any) => {


    let currentCart = { ...cartState };
    if (currentCart == null || Object.keys(currentCart).length == 0) {
        currentCart = {
            cart_items: []
        }
    }

    const newCartItem = {
        cart_item_id: cart_item_id,
        recipe_id: recipe_id,
        product: product,
        quantity: quantity
    };


    currentCart.cart_items.push(newCartItem);
    setCartState(currentCart);
    localStorage.setItem(localStorageItemMap.cart, JSON.stringify(currentCart));
}

export const removeCartItem = (cartState: any, setCartState: any, cart_item_id: string) => {
    const currentCart = { ...cartState };
    const cartItemIndex = currentCart.cart_items.findIndex(cartItem => cartItem.cart_item_id == cart_item_id);
    
    if (cartItemIndex != -1) {
        currentCart.cart_items.splice(cartItemIndex, 1);
        setCartState(currentCart);
        localStorage.setItem(localStorageItemMap.cart, JSON.stringify(currentCart));
    }
}

export const updateCartItemQuantity = (cartState: any, setCartState: any, cart_item_id: string, quantity: number) => {
    const currentCart = { ...cartState };

    const cartItemIndex = currentCart.cart_items.findIndex(cartItem => cartItem.cart_item_id == cart_item_id);

    if (cartItemIndex != -1) {
        currentCart.cart_items[cartItemIndex].quantity = quantity;
    }

    setCartState(currentCart);
    localStorage.setItem(localStorageItemMap.cart, JSON.stringify(currentCart));
}

export const updateCartItem = (cartState: any, setCartState: any, cart_item_id: string, recipe_id: string, product: any) => {
    const currentCart = { ...cartState };

    const cartItemIndex = currentCart.cart_items.findIndex(cartItem => cartItem.cart_item_id == cart_item_id);

    if (cartItemIndex != -1) {
        currentCart.cart_items[cartItemIndex].recipe_id = recipe_id;
        currentCart.cart_items[cartItemIndex].product = product;
    }

    setCartState(currentCart);
    localStorage.setItem(localStorageItemMap.cart, JSON.stringify(currentCart));
}

export const clearCart = (setCartState: any) => {
    const currentCart = {
        cart_items: []
    }

    setCartState(currentCart);
    localStorage.setItem(localStorageItemMap.cart, JSON.stringify(currentCart));
};

export const localiseCartItemProductDisplayNames = async (cartState: any, setCartState: any, dataApiBaseUrl: string, storeId: string, locale: string) => {
    const currentCart = { ...cartState };
 
    if (currentCart.cart_items != null && currentCart.cart_items.length > 0) {
        let getProductDetailsPromiseArray = [];

        currentCart.cart_items.forEach(cartItem => {
            getProductDetailsPromiseArray.push(getProductDetails(dataApiBaseUrl, storeId, cartItem.product.ProductIndex, cartItem.product.ROC, locale, cartItem.product.productRecipe));
        });

        const [...productDetailsResponsesArray] = await Promise.all(getProductDetailsPromiseArray);

        let productDetailsArrayJsonPromises = [];

        for (let i = 0; i < productDetailsResponsesArray.length; i++) {
            productDetailsArrayJsonPromises.push(productDetailsResponsesArray[i].json());
        }

        const [...productDetailsArray] = await Promise.all(productDetailsArrayJsonPromises);
        
        for (let i = 0; i < productDetailsArray.length; i++) {
            if (currentCart.cart_items[i].product != null) {
                if (currentCart.cart_items[i].product.Name == productDetailsArray[i].Result.Name) {
                    currentCart.cart_items[i].product.DisplayName = productDetailsArray[i].Result.DisplayName;
                }
               
                // Find the subcategory to update display name localisation
                let SubCategory = null;
                if(currentCart.cart_items[i].product.ROCDetails.PdpId == "2") {
                    SubCategory = productDetailsArray[i].Result.SubCategory.find(subcategory => subcategory.ColourHex ==  currentCart.cart_items[i].product.SubCategory.ColourHex);
                }
                else {
                    SubCategory = productDetailsArray[i].Result.SubCategory.find(subcategory => subcategory.VendorReference == currentCart.cart_items[i].product.SubCategory.VendorReference);
                }
    
                if (SubCategory != null) {
                    currentCart.cart_items[i].product.SubCategory.DisplayName = SubCategory.DisplayName;
                }
    
                for (let j = 0; j < currentCart.cart_items[i].product.Upcharges.length; j++) {
                    const Upcharge = productDetailsArray[i].Result.Upcharges.find(upcharge =>
                        upcharge.Code == currentCart.cart_items[i].product.Upcharges[j].Code && upcharge.Name == currentCart.cart_items[i].product.Upcharges[j].Name
                    );
    
                    if (Upcharge != null) {
                        currentCart.cart_items[i].product.Upcharges[j].DisplayName = Upcharge.DisplayName;
                    }
                }
            }
        }
        //Update Customisation data
        const options = { cart: currentCart, dataApiBaseUrl, storeId, locale };
        const cart = await updateCartRecipesWithCustomisationNames(options);
        setCartState(cart);
        localStorage.setItem(localStorageItemMap.cart, JSON.stringify(currentCart));
    }
}

const CartContext = React.createContext({
    cart: {
        cart_items: []
    },
    addCartItem: null,
    removeCartItem: null,
    updateCartItemQuantity: null,
    updateCartItem: null,
    clearCart: null,
    localiseCartItemProductDisplayNames: null
});

export default CartContext;


