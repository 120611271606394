import React from "react";
import { localStorageItemMap } from "../helpers/local-storage-parameter-map";

// Import translation files
import en_gb from "../locales/en_gb.json";
import en_us from "../locales/en_us.json";
import zh_cn from "../locales/zh_cn.json";
import zh_tw from "../locales/zh_tw.json";
import ja_jp from "../locales/ja_jp.json";
import de_de from "../locales/de_de.json";
import fr_fr from "../locales/fr_fr.json";

// Map language code to translation JSON file
const locales = {
    de_DE: de_de,
    en_GB: en_gb,
    en_US: en_us,
    zh_CN: zh_cn,
    zh_TW: zh_tw,
    ja_JP: ja_jp,
    fr_FR: fr_fr,
};

// Translate function
export const t = (chosenLocale, key, data) => {
    let json;

    if (locales[chosenLocale]) json = locales[chosenLocale];
    else json = locales["en_US"]; // Default locale

    const elements = key.split(".");

    for (let i = 0; i < elements.length; i++) {
        const param = elements[i];

        json = json[param];
    }

    if (data != null) {
        json = json.replace("%%", data);
    }

    return json;
};

export const changeLocale = (
    setLocaleState: any,
    locale: string,
) => {
    setLocaleState(locale);
    localStorage.setItem(localStorageItemMap.locale, locale);
};

const TranslateContext = React.createContext({
    t: null,
    locale: "",
    availableLocales: [],
    changeLocale: null,
});

export default TranslateContext;
