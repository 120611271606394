// get-product-data.ts
// Functions used to get the product data and images from YR APIs

import { jsonApiRepository } from "../../repositories/json-api-repository";
import { localStorageItemMap } from "../local-storage-parameter-map";
import { checkRecipeAgainstNewROCs, getProductOptions, getRocFromRocs } from "./pdp-helpers";

export const getProductData = async (dataApiBaseUrl: string, imageApiBaseUrl: string, storeId: string, recipe: any, viewID: string, locale: string) => {

    const { imageWidth, imageHeight } = calculateImageDimensions();
    const productIndex: string = recipe.recipe.product.productIndex;
    const getRocUrl = `${dataApiBaseUrl}get-roc?store=${storeId}&width=${imageWidth}&height=${imageHeight}&productIndex=${productIndex}&locale=${locale}`;
    const getProductDetailsUrl = `${dataApiBaseUrl}get-product-details?store=${storeId}&locale=${locale}`;
    let getProductImageUrl = `${imageApiBaseUrl}get-product-image?store=${storeId}&width=${imageWidth}&height=${imageHeight}&locale=${locale}`;

    return new Promise(async (resolve, reject) => {
        try {
            let productROCsPromise;
            let productROCs, productDetails, productImage;

            try {
                productROCsPromise = fetch(getRocUrl, {
                    method: 'PUT',
                    body: JSON.stringify(recipe)
                });

                const productDetailsPromise = fetch(getProductDetailsUrl, {
                    method: 'PUT',
                    body: JSON.stringify(recipe)
                });

                if (viewID != null && viewID != "") {
                    getProductImageUrl += `&view=${viewID}`
                }

                const productImagePromise = fetch(getProductImageUrl, {
                    method: 'PUT',
                    body: JSON.stringify(recipe)
                });

                [productROCs, productDetails, productImage] = await Promise.all([productROCsPromise, productDetailsPromise, productImagePromise]);

            }
            catch (error) {
                reject(error);
            }
            const productRocsJSONPromise = productROCs.json();
            const productDetailsJSONPromise = productDetails.json();
            const productImageBlobPromise = productImage.blob();

            const [productsRocsJSON, productDetailsJSON, productImageBlob] = await Promise.all([productRocsJSONPromise, productDetailsJSONPromise, productImageBlobPromise]);
            const productImageSource = await getProductImageSource(productImageBlob);

            const result = {
                productRocsJSON: productsRocsJSON.Result,
                productDetailsJSON: productDetailsJSON.Result,
                productImage: productImageSource
            }
            resolve(result);
        }
        catch (error) {
            reject(error);
        }
    })
}

export const getProductImageSource = (blob) => {
    const reader = new FileReader();
    let result;
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {

        reader.onloadend = function () {
            result = reader.result;
            resolve(result);
        }
        reader.onerror = function (error) {

            reject(error)
        }
    });
}

export const getProductImage = (imageApiBaseUrl: string, storeId: string, productIndex: any, rocId: string, viewId: any, width: any, height: any, recipe: any, locale: string, codbg?: number, codfg?: number) => {
    let getProductImageUrl = `${imageApiBaseUrl}get-product-image?store=${storeId}&width=${width}&height=${height}&productIndex=${productIndex}&roc=${rocId}&view=${viewId}&locale=${locale}`;

    if(codfg === 1) {
        getProductImageUrl += `&codfg=${codfg}`
    }
    if(codbg === 1) {
        getProductImageUrl += `&codbg=${codbg}`
    }
    const productImage = fetch(getProductImageUrl, {
        method: 'PUT',
        body: JSON.stringify(recipe)
    });

    return productImage;
}

export const getProductImageForCod = async (imageApiBaseUrl: string, storeId: string, productIndex: any, rocId: string, viewId: any, forcePNG: boolean, locale: string, codbg?: number, codfg?: number, recipe?: any, width?: number, height?: number) => {
    
    const { imageWidth, imageHeight } = calculateImageDimensions();
    let getProductImageUrl = `${imageApiBaseUrl}get-product-image?store=${storeId}&width=${width == null ? imageWidth: width}&height=${height == null ? imageHeight: height}&productIndex=${productIndex}&roc=${rocId}&view=${viewId}&locale=${locale}`;

    if (forcePNG == true) {
        getProductImageUrl += '&format=PNG'
    }
    if(codfg === 1) {
        getProductImageUrl += `&codfg=${codfg}`
    }
    if(codbg === 1) {
        getProductImageUrl += `&codbg=${codbg}`
    }  
    const productImage = await fetch(getProductImageUrl, {
        method: 'PUT',
        body: (recipe != null) ? JSON.stringify(recipe): null
       
    });
    const prodImg = await productImage.blob();
    const imageBlob = await getProductImageSource(prodImg);
    return imageBlob;
}

export const getViewThumbnailImagesForCod = async (imageApiBaseUrl: string, storeId: string, views: any, productIndex: any, rocId: string, recipe: any, locale: string) => {
    const imagePromises = [];
    const width = 150;
    const height = 150;

    for (let view of views) {

        const backgroundImagePromise = await getProductImageForCod(imageApiBaseUrl, storeId, productIndex, rocId, view.ID, true, locale, 1, 0, null, width, height);
        const foregroundImagePromise = await getProductImageForCod(imageApiBaseUrl, storeId, productIndex, rocId, view.ID, true , locale, 0, 1, recipe, width, height);
        imagePromises.push(foregroundImagePromise, backgroundImagePromise);
    }

    const [...images] = await Promise.all([...imagePromises]);

    return images;
}

export const getViewThumbnailImages = async (imageApiBaseUrl: string, storeId: string, views: any, productIndex: any, rocId: string, recipe: any, locale: string) => {
    const imageBinariesPromises = [];
    const width = 150;
    const height = 150;

    for (let view of views) {

        const imagePromise = getProductImage(imageApiBaseUrl, storeId, productIndex, rocId, view.ID, width, height, recipe, locale);
        imageBinariesPromises.push(imagePromise);
    }

    const [...imageBinaries]: any = await Promise.all([...imageBinariesPromises]);

    let blobPromises = [];

    for (let image of imageBinaries) {
        blobPromises.push(image.blob());
    }

    const [...blobs] = await Promise.all([...blobPromises]);

    const imagePromises = [];

    for (let blob of blobs) {
        imagePromises.push(getProductImageSource(blob));
    }

    const [...images] = await Promise.all([...imagePromises]);

    return images;
}

export const getImageFromRecipe = async (imageApiBaseUrl: string, storeId: string, recipe: any, view: any, locale: string) => {
    const { imageWidth, imageHeight } = calculateImageDimensions();

    let getProductImageUrl = `${imageApiBaseUrl}get-product-image?store=${storeId}&width=${imageWidth}&height=${imageHeight}&view=${view}&locale=${locale}`;
    const forcePNG = localStorage.getItem(localStorageItemMap.forcePNG);
    if (forcePNG == "true") {
        getProductImageUrl += "&format=PNG";
    }

    const productImage = await fetch(getProductImageUrl, {
        method: 'PUT',
        body: JSON.stringify(recipe)
    });
    const imageBlob = await productImage.blob();
    return await getProductImageSource(imageBlob);
}

export const getQuickAddCartModalImagesAndProductDetails = async (options) => {
    const { url, dataUrl, storeId, roc, locale } = options;
    const imageBinariesPromises = [];
    const productDetailsPromises = [];
    const productOptionsPromises = [];
    const width = 300;
    const height = 300;
    const printViewFront = '00001.1'
    const updatedRecipes: any = await getROCsAndCheckRecipesForQuickAddItems(options);

    for (let recipe of updatedRecipes) {
        const productIndex = recipe.recipe.product.productIndex;
        const imagePromise = getProductImage(url, storeId, productIndex, roc, printViewFront, width, height, recipe, locale);
        const productOptionsPromise = jsonApiRepository.getProductOptions(dataUrl, storeId, productIndex, recipe, locale);
        const productDetailsPromise = getProductDetails(dataUrl, storeId, productIndex, roc, locale, recipe);
        imageBinariesPromises.push(imagePromise);
        productDetailsPromises.push(productDetailsPromise);
        productOptionsPromises.push(productOptionsPromise);
    }

    const [...imageBinaries]: any = await Promise.all([...imageBinariesPromises]);
    const [...productDetails]: any = await Promise.all([...productDetailsPromises]);
    const [...productOptions]: any = await Promise.all([...productOptionsPromises]);   

    let blobPromises = [];

    for (let image of imageBinaries) {
        blobPromises.push(image.blob());
    }

    let productDetailsJSONPromises = [];

    for (let details of productDetails) {
        productDetailsJSONPromises.push(details.json());
    }

    const [...blobs] = await Promise.all([...blobPromises]);
    const [...productDetailsJSONs] = await Promise.all([...productDetailsJSONPromises]);

    const imagePromises = [];

    for (let blob of blobs) {
        imagePromises.push(getProductImageSource(blob));
    }

    for (let i = 0; i < productDetailsJSONs.length; i++) {

        const option = getProductOptions(productOptions[i], 0);       
        productDetailsJSONs[i].Result.Option = option;
    }

    const [...images] = await Promise.all([...imagePromises]);

    return { images, productDetailsJSONs, productOptions };
}

export const getROCsAndCheckRecipesForQuickAddItems = async (options) => {
    const { dataUrl, storeId, recipes, locale } = options;
    const ROC = { ID: "00001" }
    const ROCPromises = [];

    for (let recipe of recipes) {
        const productIndex = recipe.recipe.product.productIndex.productIndex;
        const ROCPromise = getROCFromProductIndex(dataUrl, storeId, productIndex, recipe, locale);
        ROCPromises.push(ROCPromise);
    }
    const [...ROCs]: any = await Promise.all([...ROCPromises]);
    let RocJsonPromises = [];

    for (let roc of ROCs) {
        RocJsonPromises.push(roc.json());
    }
    const [...RocJSONs] = await Promise.all([...RocJsonPromises]);
    const validatedRecipes = [];

    for (let i = 0; i < RocJSONs.length; i++) {
        const currentROC = getRocFromRocs(ROC, RocJSONs[i].Result.ROC);
        const validatedRecipe = checkRecipeAgainstNewROCs(recipes[i], currentROC);
        validatedRecipes.push(validatedRecipe);
    }

    return validatedRecipes;
}

export const getProductDetails = (dataApiBaseUrl: string, storeId: string, productIndex: any, roc: any, locale: string, recipe: any) => {
    const getProductDetailsUrl = `${dataApiBaseUrl}get-product-details?store=${storeId}&productIndex=${productIndex}&roc=${roc}&locale=${locale}`;

    const productDetailsPromise = fetch(getProductDetailsUrl, {
        method: 'PUT',
        body: JSON.stringify(recipe)
    });

    return productDetailsPromise;
}

export const getROCFromProductIndex = (dataApiBaseUrl: string, storeId: string, productIndex: any, recipe: any, locale: string) => {
    const { imageWidth, imageHeight } = calculateImageDimensions();

    const getRocUrl = `${dataApiBaseUrl}get-roc?store=${storeId}&width=${imageWidth}&height=${imageHeight}&productIndex=${productIndex}&locale=${locale}`;

    const productROCsPromise = fetch(getRocUrl, {
        method: 'PUT',
        body: JSON.stringify(recipe)
    });

    return productROCsPromise;
}

export const getConfigureDetails = async (dataApiBaseUrl: string, storeId: string, recipe: any, area: any, asset: any, productIndex: any, locale: string) => {
    const getConfigureDetailsUrl = `${dataApiBaseUrl}get-configure-details?store=${storeId}&area=${area.ID}&opt1=${asset}&productIndex=${productIndex}&locale=${locale}`;

    const getConfigureDetailsPromise = await fetch(getConfigureDetailsUrl, {
        method: 'PUT',
        body: JSON.stringify(recipe)
    });

    const result = await getConfigureDetailsPromise.json();

    return result.Result;
}

export const getThumbnailUrl = (imageApiBaseUrl: string, storeId: string, thumbLink: string) => {
    let url = `${imageApiBaseUrl}${thumbLink}&store=${storeId}&width=150&height=150`
    const forcePNG = localStorage.getItem(localStorageItemMap.forcePNG);
    if (forcePNG == "true") {
        url += "&format=PNG";
    }
    return url;
}

export const getROCsForBlankProduct = async (dataApiBaseUrl: string, storeId: string, locale: string, productIndex: any, recipe: any) => {
    const { imageWidth, imageHeight } = calculateImageDimensions();

    const getRocUrl = `${dataApiBaseUrl}get-roc?store=${storeId}&locale=${locale}&width=${imageWidth}&height=${imageHeight}&productIndex=${productIndex}`;

    const productROCsPromise = await fetch(getRocUrl, {
        method: 'PUT',
        body: JSON.stringify(recipe)
    });

    const result = await productROCsPromise.json();

    return result.Result.ROC;
}

export const getDesignImage = async (imageApiBaseUrl: string, url: string, locale: string, forcePNG = false) => {

    const width = Math.min(window.innerWidth, 1080);
    let height = window.innerHeight;

    let imageWidth;

    if (width > height) {
        imageWidth = height;
    }
    else {
        imageWidth = width;
    }

    let getDesignImageUrl = `${imageApiBaseUrl}${url}&width=${imageWidth}&height=${imageWidth}&locale=${locale}`;

    if (forcePNG == true) {
        getDesignImageUrl += "&format=PNG";
    }

    const designImagePromise = await fetch(getDesignImageUrl, {
        method: 'GET'
    });

    const designImageBlob = await designImagePromise.blob();
    const designImage = await getProductImageSource(designImageBlob);

    return designImage;
}

export const updateAssetOnColourChange = (imageApiBaseUrl: string, storeId: string, option2, option3, text, locale, forcePNG=false) => {
    let designLink = option2.DesignLink;
    if (option3.DesignLink != null && option3.DesignLink != "") {
        designLink = option3.DesignLink;
    }
    designLink += `&store=${storeId}`;
    let getDesignImageUrl = `${designLink}&opt3=${option3.ID}`;
    getDesignImageUrl += text.text1 ? `&text1=${text.text1}` : '';
    getDesignImageUrl += text.text2 ? `&text2=${text.text2}` : '';

    const image = getDesignImage(imageApiBaseUrl, getDesignImageUrl, locale, forcePNG);
    return image;
}

export const calculateImageDimensions = () => {
    //Aspect ratio 2:3

    const imageWidth = window.innerWidth;
    const imageHeight = Math.round((imageWidth / 2) * 3);
    return { imageWidth, imageHeight };
}