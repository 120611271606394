import React from "react";
import Loader from "./Loader";
import CoDBg_3X from "../assets/images/ColorBurstBG@3x.jpg";
import CoDBg from "../assets/images/ColorBurstBG.jpg";

class Page extends React.Component<any, any> {

    buttonRef: any;

    constructor(props) {
        super(props);
        this.state = ({});
    }

    componentDidMount() {
        this.props.pageLoading ? this.props.setShowLoader(true) : this.props.setShowLoader(false);
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.pageLoading !== previousProps.pageLoading) {
            this.props.pageLoading ? this.props.setShowLoader(true) : this.props.setShowLoader(false);
        }
    }

    render() {


        let CodBackgroundImage = null;
       
        let className = 'page';
        if (this.props.theme == "theme-light") {
            className += ` theme-light`
        }
        else if (this.props.theme == "theme-dark") {
            const pageWidth = window.innerWidth;
            CodBackgroundImage = {backgroundImage: `url(${pageWidth <= 500 ? CoDBg: CoDBg_3X})`};
            className += ` theme-dark`
        }

        if (this.props.pageLoading) {
            className += ' blur';
        }
        if (this.props.zoomEffect) {
            className += ' zoomPage';
        }
        return (
            <div style={CodBackgroundImage == null ? {}: CodBackgroundImage} className={className}>
                {this.props.children}
            </div>
        );
    }
}

export default Page;