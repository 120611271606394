import React from "react";
import styles from "../../../styles/ChangeProductSkeletonLoader.module.scss";
import skeletonLoaderStyles from "../../../styles/SkeletonLoader.module.scss";

export const ChangeProductSkeletonLoaderThumbnail = ({ style }) => {
    return (
        <div className={styles[`change-product-modal-thumbnail-container`]} style={style ? style : null}>
            <div className={styles[`change-product-modal-thumbnail-image-container`]}>
                <div className={styles[`change-product-modal-thumbnail-image`] + " " + skeletonLoaderStyles[`loading`]} />
            </div>
            <div>
                <div className={styles[`change-product-modal-thumbnail-line`] + " " + skeletonLoaderStyles[`loading`]}></div>
                <div className={styles[`change-product-modal-thumbnail-line`] + " " + skeletonLoaderStyles[`loading`]}></div>
            </div>
        </div>
    )
}