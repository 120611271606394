import React, { useEffect, useRef, useState, useCallback } from 'react';
import './styles/globals.scss';
import { Switch, Route, useLocation } from 'react-router-dom';
import * as FullStory from "@fullstory/browser";
import { DevPasswordOverlay } from './components/DevPasswordOverlay';
import { Meta } from './components/Meta';
import Navbar from './components/navbar/navbar';
import CartContext, { addCartItem, removeCartItem, updateCartItemQuantity, updateCartItem, clearCart, localiseCartItemProductDisplayNames } from './providers/CartContext';
import TranslateContext, { t, changeLocale } from './providers/TranslateContext';
import locales from "./locales/availble_locales.json";
import { storeRepository } from './repositories/store-repository';
import { useHistory } from "react-router-dom";
import { getQueryParametersFromUrl } from './helpers/get-query-parameters-from-url';
import Page from './components/Page';
import LoaderForBlurEffect from './components/LoaderForBlurEffect';
import ScreensaverModal from './components/ScreensaverModal';
import { Screensaver } from './components/Screensaver';
import { getKioskModeFromKioskString } from "./helpers/get-kiosk-mode-from-kiosk-string";
import ErrorBoundary from './components/ErrorBoundary';
import ProgressBar from './components/ProgressBar';
import { localStorageItemMap, queryParameterMap } from './helpers/local-storage-parameter-map';
import { deleteAllCookies } from './helpers/get-cookie';
import { sendEvent } from './helpers/analytics/send-event';
import { sendFullStoryPageView } from './helpers/analytics/fullstory-events';

const HomePage = React.lazy(() => import('./components/Homepage'));
const ProductPage = React.lazy(() => import(/* webpackPrefetch: true */ './pages/product-designer/design')); //import('./pages/product-designer/design'));
const CartPage = React.lazy(() => import('./pages/basket'));
const CheckoutPage = React.lazy(() => import('./pages/checkout'));
const OrderConfirmationPage = React.lazy(() => import('./pages/order-confirmation'));
const PrivacyPolicyPage = React.lazy(() => import('./pages/privacy-policy'));

function App(props) {
    const [cartState, setCartState] = useState(JSON.parse(localStorage.getItem(localStorageItemMap.cart)));
    const [localisationModalVisible, setLocalisationModalVisible] = useState(false);
    const [navbarVisible, setNavbarVisible] = useState(null);
    const [kioskMode, setKioskMode] = useState(false);
    const [disableLangChange, setDisableLangChange] = useState(false);
    const [confirmedOrders, setConfirmedOrders] = useState(false);
    const [store, setStore] = useState({StoreCode: "", Default_Locale: ""});
    const [store_json, setStoreJSON] = useState(null);
    const [storeTerminalId, setStoreTerminalId] = useState("");
    const [collectionNote, setCollectionNote] = useState(false);
    const [dataApiBaseUrl, setDataApiBaseUrl] = useState("");
    const [imageApiBaseUrl, setImageApiBaseUrl] = useState("");
    const [locale, setLocale] = useState(localStorage.getItem(localStorageItemMap.locale) ? localStorage.getItem(localStorageItemMap.locale) : "");
    const [availableLocales, setAvailableLocales] = useState([]);
    const [disableStorePickup, setDisableStorePickup] = useState(false);
    const [screensaverModalVisible, setScreensaverModalVisible] = useState(false);
    const [screensaverVideoVisible, setScreensaverVideoVisible] = useState(false);
    const [notificationVisible, setNotificationVisible] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [theme, setTheme] = useState("theme-light");
    const [showThemeSwitcher, setShowThemeSwitcher] = useState(false);
    const pageRef = useRef(null);
    const historyHook = useHistory();
    const location = useLocation();
    const [pageLoading, setPageLoading] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [zoom, setZoom] = useState(false);
    const [shippingCountries, setShippingCountries] = useState([]);
    const [quickAdd, setQuickAdd] = useState(false);
    const screensaverVideoVisibleRef = React.useRef(screensaverVideoVisible);
    const storeJsonRef = React.useRef(store_json);
    const storeRef = React.useRef(store);
    const routes = ['/', '/product-designer', '/basket', '/checkout', '/order-confirmation', '/privacy-policy', "/colour-on-demand"];
    let modalTimeout = React.useRef<any>();
    let videoTimeout =  React.useRef<any>();

    const _setScreensaverVideoVisible = (data) => {
        screensaverVideoVisibleRef.current = data;
        setScreensaverVideoVisible(data);
    }

    const _setStore = (store) => {
        storeRef.current = store;
        const eventCategory = "store_id";
        const eventAction = "StoreID";
        const eventLabel = store.StoreCode || "";
        sendEvent(eventCategory, eventAction, eventLabel, store.StoreCode);
        setStore(store);
    }

    const _setStoreJSON = (data) => {
        storeJsonRef.current = data;
        setStoreJSON(data);
    }

    useEffect(() => {

        const history = historyHook;
        const path = location.pathname;
        const validRoute = routes.includes(path) || path.startsWith('/product-designer/') || path.startsWith("/colour-on-demand");

        //Set cart
        const cart = JSON.parse(localStorage.getItem(localStorageItemMap.cart));

        if (cart == null) {
            const cart = {
                cart_items: []
            };
            localStorage.setItem(localStorageItemMap.cart, JSON.stringify(cart));
        }

        setCartState(cart);
        getStoreMap();

    }, []);

    useEffect(() => {
        const cleanInputBeforeCloseTab = (e: PageTransitionEvent) => {
          e.preventDefault();
          _clearCart();
          // if ga_session_id is not expired and the terminal closes or crashes the browser
          // this code is making sure the same session_hash does not register more events
          // this could only happen if the session ga_session_id is still active by the time browser is closed.
          if (localStorage.getItem(localStorageItemMap.sessionHash)) {
            sendEvent("reset_session", `close_tab_session_reset`, `Close Tab Session Reset`, store.StoreCode, {
                session_hash: 'no_current_session'
            });
          }
          localStorage.removeItem(localStorageItemMap.sessionHash)
        }
        window.addEventListener('pagehide', cleanInputBeforeCloseTab);
    
        return () => window.removeEventListener('pagehide', cleanInputBeforeCloseTab);
    });

    useEffect(() => {
        if (store_json != null) {
            if (store_json.FullStoryEnabled && !FullStory.isInitialized()) {
                FullStory.init({
                    orgId: process.env.REACT_APP_FULLSTORY_ORGID
                }, () => {
                    console.log("Stated FS Session");
                });
            } else if (!store_json.FullStoryEnabled && FullStory.isInitialized()) {
                FullStory.shutdown();
            }
        }
    }, [store_json]);

    useEffect(() => {
        if (FullStory.isInitialized()) {
            sendFullStoryPageView(window.location.href, {
                kioskMode,
            });
        }
    }, [historyHook.location, kioskMode]);

    const getStoreMap = async (defaultStore = null, source: string = null) => {

        const history = historyHook;
        const path = location.pathname;
        const validRoute = routes.includes(path) || path.startsWith('/product-designer/') || path.startsWith("/colour-on-demand");
        
        try {
            const storeIdFromPath = path.substring(1);
            const storeId = defaultStore != null ? defaultStore.StoreCode: storeIdFromPath;

            const storeMap = await storeRepository.getStoreMap();
            let validStore = storeMap.Stores.find(store => {
                if(store == null)
                    return false;
                return store.StoreCode == storeId;
            });
            // Retrieve store local storage item
            const storeLocalStorageItem = localStorage.getItem(localStorageItemMap.store);
            const store = JSON.parse(storeLocalStorageItem); 

            if (validStore) {
                const { codMode, codDefaultProduct } = await setupStore(validStore);
                
                if (codMode != null && codMode === true) {
                    if (source === "navbar" || (!path.startsWith("/basket") && !path.startsWith("/checkout") && !path.startsWith("/order-confirmation") && !path.startsWith("/privacy-policy"))) {                
                        const designPageUrl = `/product-designer/${codDefaultProduct}?mode=product`;
                        history.push(designPageUrl);
                    }
                } else {
                    history.push("/");
                }
            }
            else if (!store || !validRoute) {
                history.push("/error");
            }
            else {
                const {codMode, codDefaultProduct} = await setupStore(store);

                if(codMode != null && codMode === true) {
                    if (source === "navbar" || (!path.startsWith("/basket") && !path.startsWith("/checkout") && !path.startsWith("/order-confirmation") && !path.startsWith("/privacy-policy"))) {              
                        const designPageUrl = `/product-designer/${codDefaultProduct}?mode=product`;
                        history.push(designPageUrl);
                    }
                }
            }
        }
        catch (error) {
            console.log(error);
            history.push("/error");
        }
    }
        
    const setDataApiUrl = (queryParameters: any) => {
        let dataApiUrlLocalStorageItem = localStorage.getItem(localStorageItemMap.dataApiUrl);
        let dataApiBaseUrl = dataApiUrlLocalStorageItem;

        const dataApiBaseUrlParam = queryParameters[queryParameterMap.dataApiUrl];

        if (dataApiBaseUrlParam != null && dataApiBaseUrlParam != "") {
            dataApiBaseUrl = dataApiBaseUrlParam;
        }
        else if (dataApiUrlLocalStorageItem == null || dataApiUrlLocalStorageItem == "" || dataApiUrlLocalStorageItem == "undefined" || dataApiBaseUrlParam == "") {
            dataApiBaseUrl = process.env.REACT_APP_JSON_API_BASE_URL;
        }

        localStorage.setItem(localStorageItemMap.dataApiUrl, dataApiBaseUrl);
        dataApiBaseUrl = decodeURIComponent(dataApiBaseUrl);
        setDataApiBaseUrl(dataApiBaseUrl);
    }

    const setImageApiUrl = ( queryParameters: any) => {
        let imageApiUrlLocalStorageItem = localStorage.getItem(localStorageItemMap.imageApiUrl);
        let imageApiBaseUrl = imageApiUrlLocalStorageItem;

        const imageApiBaseUrlParam = queryParameters[queryParameterMap.imageApiUrl];

        if (imageApiBaseUrlParam != null && imageApiBaseUrlParam != "") {
            imageApiBaseUrl = imageApiBaseUrlParam;
        }
        else if (imageApiUrlLocalStorageItem == null || imageApiUrlLocalStorageItem == "" || imageApiUrlLocalStorageItem == "undefined" || imageApiBaseUrlParam == "") {
            imageApiBaseUrl = process.env.REACT_APP_IMAGE_API_BASE_URL;
        }

        localStorage.setItem(localStorageItemMap.imageApiUrl, imageApiBaseUrl);

        imageApiBaseUrl = decodeURIComponent(imageApiBaseUrl);
        setImageApiBaseUrl(imageApiBaseUrl);
    }

    const getKioskLocalStorageItem = () => {
        const kioskLocalStorageItem = localStorage.getItem(localStorageItemMap.kiosk);
        const kioskMode = getKioskModeFromKioskString(kioskLocalStorageItem);
        return kioskMode;
    }

    const setupKioskMode = (queryParameters?: any) => {
        let kioskLocalStorageItem = localStorage.getItem(localStorageItemMap.kiosk);
        let kioskMode = getKioskModeFromKioskString(kioskLocalStorageItem);

        if(queryParameters == null ) {
            queryParameters = getQueryParametersFromUrl(window.location.href);
        }
         
        const kioskParam = queryParameters[queryParameterMap.kiosk];

        if (kioskParam != null && (kioskParam == "true" || kioskParam == "false")) {
            kioskMode = getKioskModeFromKioskString(kioskParam);  
        }
        else if (kioskLocalStorageItem == null || kioskLocalStorageItem == "" || kioskLocalStorageItem == "undefined") {
            kioskMode = false;
        }

        localStorage.setItem(localStorageItemMap.kiosk, JSON.stringify(kioskMode));  
        setKioskMode(kioskMode);

        return {
            kioskMode,
        };
    }

    const setupTerminalId = (queryParameters?: any) => {
        let terminalId = "";

        if (queryParameters === null) {
            queryParameters = getQueryParametersFromUrl(window.location.href);
        }

        const terminalIdParam = queryParameters[queryParameterMap.terminalId];

        if (terminalIdParam !== null && terminalIdParam !== "" && terminalIdParam !== "undefined") {
            terminalId = terminalIdParam;
        } else {
            terminalId = "undefined";
        }

        return terminalId
    }

    const setDisableLangChangeLocalStorageItem = (queryParameters: any) => {
        const disableLangChangeLocalStorageItem = localStorage.getItem(localStorageItemMap.disableLangChange);
        let disableLangChangeString = disableLangChangeLocalStorageItem;

        const disableLangChangeParam = queryParameters[queryParameterMap.disableLangChange];

        if(disableLangChangeParam == "true" || disableLangChangeParam == "false") {
            disableLangChangeString = disableLangChangeParam;
        }
        else if(disableLangChangeLocalStorageItem == null || disableLangChangeLocalStorageItem == ""  || disableLangChangeLocalStorageItem == "undefined") {
            disableLangChangeString = "false";
        }

        localStorage.setItem(localStorageItemMap.disableLangChange, disableLangChangeString);

        let disableLangChange = false;
        if (disableLangChangeString == "true") {
            disableLangChange = true;
        }

        setDisableLangChange(disableLangChange);
        showNavbar();
    }

    const setForcePngLocalStorageItem = (queryParameters?: any) => {
        const forcePngLocalStorageItem = localStorage.getItem(localStorageItemMap.forcePNG);
        let forcePngString = forcePngLocalStorageItem;

        if(queryParameters == null) {
            queryParameters = getQueryParametersFromUrl(window.location.href);
        }

        const forcePNG = queryParameters[queryParameterMap.forcePNG];

        if (forcePNG == "true" || forcePNG == "false") {
            forcePngString = forcePNG;
        }
        else if (forcePngLocalStorageItem == null || forcePngLocalStorageItem == "" || forcePngLocalStorageItem == "undefined") {
            forcePngString = "false";
        }

        localStorage.setItem(localStorageItemMap.forcePNG, forcePngString);
    }

    const setDebugLocalStorageItem = (queryParameters: any) => {
        const debugLocalStorageItem = localStorage.getItem(localStorageItemMap.debug);
        const debugParam = queryParameters[queryParameterMap.debug];

        let debugString = debugLocalStorageItem;

        if (debugParam == "true" || debugParam == "false") {
            debugString = debugParam;
        }
        else if (debugLocalStorageItem == null || debugLocalStorageItem == "" || debugLocalStorageItem == "undefined") {
            debugString = "false";
        }

        localStorage.setItem(localStorageItemMap.debug, debugString);
    }

    const setupQuickAdd = (queryParameters: any) => {
        const quickAddLocalStorageItem = localStorage.getItem(localStorageItemMap.quickAdd);
        let quickAddString = quickAddLocalStorageItem;

        const quickAddParam = queryParameters[queryParameterMap.quickAdd];

        if (quickAddParam == "true" || quickAddParam == "false") {
            quickAddString = quickAddParam;
        }
        else if (quickAddLocalStorageItem == null || quickAddLocalStorageItem == "" || quickAddLocalStorageItem == "undefined") {
            quickAddString = "false";
        }

        localStorage.setItem(localStorageItemMap.quickAdd, quickAddString);
        
        let quickAdd = false;
        if (quickAddString == "true") {
            quickAdd = true
        }

        setQuickAdd(quickAdd);
    }

    const setLocalPrintLocalStorageItem = (queryParameters: any) => {
        const localPrintStorageItem = localStorage.getItem(localStorageItemMap.localPrint);

        const localPrintParam = queryParameters[queryParameterMap.localPrint];
        let localPrintString = localPrintStorageItem;

        if (localPrintParam == "true" || localPrintParam == "false") {
            localPrintString = localPrintParam;
        }
        else if (localPrintStorageItem == null || localPrintStorageItem == "" || localPrintStorageItem == "undefined") {
            localPrintString = "false";
        }

        localStorage.setItem(localStorageItemMap.localPrint, localPrintString);
    }

    const setBarcodeFormatLocalStorageItem = (queryParameters: any) => {
        const barcodeFormatLocalStorageItem = localStorage.getItem(localStorageItemMap.barcodeFormat);
        let barcodeFormatString = barcodeFormatLocalStorageItem;

        const barcodeFormatParam = queryParameters[queryParameterMap.barcodeFormat];

        if (barcodeFormatParam != null && barcodeFormatParam != "") {
            barcodeFormatString = barcodeFormatParam;
        }
        else if (barcodeFormatLocalStorageItem == null || barcodeFormatLocalStorageItem == "" || barcodeFormatLocalStorageItem == "undefined") {
            barcodeFormatString = "UPC";
        }

        localStorage.setItem(localStorageItemMap.barcodeFormat, barcodeFormatString);
    }

    const setCollectionNoteLocalStorageItem = (queryParameters) => {
        const collectionNoteLocalStorageItem = localStorage.getItem(localStorageItemMap.collectionNote);
        let collectionNoteString = collectionNoteLocalStorageItem;

        const collectionNoteParam = queryParameters[queryParameterMap.collectionNote];

        if (collectionNoteParam == "true" || collectionNoteParam == "false") {
            collectionNoteString = collectionNoteParam;
        }
        else if (collectionNoteLocalStorageItem == null || collectionNoteLocalStorageItem == "" || collectionNoteLocalStorageItem == "undefined") {
            collectionNoteString = "false";
        }

        localStorage.setItem(localStorageItemMap.collectionNote, collectionNoteString);

        let collectionNote = false;
        if (collectionNoteString == "true") {
            collectionNote = true;
        }

        setCollectionNote(collectionNote);
    }

    const setConfirmedOrdersLocalStorageItem = (queryParameters: any) => {
        const confirmedOrdersLocalStorageItem = localStorage.getItem(localStorageItemMap.confirmedOrders);
        let confirmedOrdersString = confirmedOrdersLocalStorageItem;

        const confirmedOrdersParam = queryParameters[queryParameterMap.confirmedOrders];

        if (confirmedOrdersParam == "true" || confirmedOrdersParam == "false") {
            confirmedOrdersString = confirmedOrdersParam;
        }
        else if (confirmedOrdersLocalStorageItem == null || confirmedOrdersLocalStorageItem == "" || confirmedOrdersLocalStorageItem == "undefined") {
            confirmedOrdersString = "false";
        }

        localStorage.setItem(localStorageItemMap.confirmedOrders, confirmedOrdersString);
        let confirmedOrders = false;

        if (confirmedOrdersString == "true") {
            confirmedOrders = true;
        }

        setConfirmedOrders(confirmedOrders);
    }

    const setDisableStorePickupLocalStorageItem = (queryParameters: any) => {
        const disableStorePickupLocalStorageItem = localStorage.getItem(localStorageItemMap.disableStorePickup);
        let disableStorePickupString = disableStorePickupLocalStorageItem;

        const disableStorePickupParam = queryParameters[queryParameterMap.disableStorePickup];

        if (disableStorePickupParam == "true" || disableStorePickupParam == "false") {
            disableStorePickupString = disableStorePickupParam;
            localStorage.setItem(localStorageItemMap.disableStorePickup, disableStorePickupString);
        }
        else if (disableStorePickupLocalStorageItem == null || disableStorePickupLocalStorageItem == "" || disableStorePickupLocalStorageItem == "undefined") {
            disableStorePickupString = "false";
            localStorage.setItem(localStorageItemMap.disableStorePickup, disableStorePickupString);
        }

        let disableStorePickup = false;

        if (disableStorePickupString == "true") {
            disableStorePickup = true
        }

        setDisableStorePickup(disableStorePickup);
    }

    const setIFrameLocalStorageItem = (queryParameters: any) => {
        const iFrameLocalStorageItem = localStorage.getItem(localStorageItemMap.iFrame);

        const iFrameParam = queryParameters[queryParameterMap.iFrame];
        let iFrameString = iFrameLocalStorageItem;

        if (iFrameParam == "true" || iFrameParam == "false") {
            iFrameString = iFrameParam;
        }
        else if (iFrameLocalStorageItem == null || iFrameLocalStorageItem == "" || iFrameLocalStorageItem == "undefined") {
            iFrameString = "false";
        }

        localStorage.setItem(localStorageItemMap.iFrame, iFrameString);

        if(iFrameString === "true") {
            deleteAllCookies();
        }
    }

    const updateQuickAddLocalStorageItem = (newValue: boolean) => {
        const kiosk = kioskMode;
        const quickAddStorageItem = localStorage.getItem(localStorageItemMap.quickAdd);
        let quickAddString = quickAddStorageItem;

        let newValueString = "false";
        
        if(newValue == true) {
            newValueString = "true";
        }

        if (newValueString == "true" || newValueString == "false") {
            quickAddString = newValueString;
            localStorage.setItem(localStorageItemMap.quickAdd, quickAddString);
        }
        else if (quickAddStorageItem == null || quickAddStorageItem == "" || quickAddStorageItem == "undefined") {
            quickAddString = "false";
            localStorage.setItem(localStorageItemMap.quickAdd, quickAddString);
        }
        
        let quickAdd = false;

        if (quickAddString == "true") {
            quickAdd = true
        }

        setQuickAdd(quickAdd);
    }

    const updateDisableStorePickupLocalStorageItem = (newValue: boolean) => {
        const disableStorePickupLocalStorageItem = localStorage.getItem(localStorageItemMap.disableStorePickup);
        let disableStorePickupString = disableStorePickupLocalStorageItem;

        let newValueString = "false";

        if(newValue == true) {
            newValueString = "true";
        }

        if (newValueString == "true" || newValueString == "false") {
            disableStorePickupString = newValueString;
        }
        else if (disableStorePickupLocalStorageItem == null || disableStorePickupLocalStorageItem == "" || disableStorePickupLocalStorageItem == "undefined") {
            disableStorePickupString = "false";
        }

        localStorage.setItem(localStorageItemMap.disableStorePickup, disableStorePickupString);

        let disableStorePickup = false;
        if (disableStorePickupString == "true") {
            disableStorePickup = true
        }

        setDisableStorePickup(disableStorePickup);
    }

    const updateStoreJson = async (storeId, locale) => {
        const getStoreJsonResponse = await storeRepository.getStoreJSON(storeId, locale);

        if (getStoreJsonResponse.error != null) {
            throw new Error(getStoreJsonResponse.error);
        }

        _setStoreJSON(getStoreJsonResponse.storeJson);

        const shippingCountries = getStoreJsonResponse.storeJson.Shipping_Countries;
        setShippingCountries(shippingCountries);

        return getStoreJsonResponse.storeJson;
    }

    const _changeLocale = async (updatedLocale: string, store_id?: string, kiosk: boolean = kioskMode) => {
        changeLocale(setLocale, updatedLocale);

        let storeId = store.StoreCode;

        if (store_id != null) {
            storeId = store_id
        }

        const storeJson = await updateStoreJson(storeId, updatedLocale);

        return {
            updatedLocale,
            storeJson
        }
    }

    const _t = (key: string, data: any = null) => {
        return t(locale, key, data);
    }

    const _addCartItem = (cart_item_id: string, recipe_id: string, quantity: number, product: any) => {
        addCartItem(cartState, setCartState, cart_item_id, recipe_id, quantity, product);
    };

    const _removeCartItem = (cart_item_id: string) => {
        removeCartItem(cartState, setCartState, cart_item_id);
    }

    const _updateCartItemQuantity = (cart_item_id: string, quantity: number) => {
        updateCartItemQuantity(cartState, setCartState, cart_item_id, quantity);
    }

    const _updateCartItem = (cart_item_id: string, recipe_id: string, product: any) => {
        updateCartItem(cartState, setCartState, cart_item_id, recipe_id, product);
    }

    const _clearCart = () => {
        clearCart(setCartState);
    }

    const _localiseCartItemProductDisplayNames = async (newLocale?: string) => {
        await localiseCartItemProductDisplayNames(cartState, setCartState, dataApiBaseUrl, store.StoreCode, newLocale ? newLocale : locale);
    }

    const showLocalisationModal = () => {
        setLocalisationModalVisible(true);

    }

    const hideLocalisationModal = () => {
        setLocalisationModalVisible(false);

    }
    const hideNavbar = () => {
        setNavbarVisible(false);
    }
    const showNavbar = () => {
        setNavbarVisible(true);
    }

    const showNotification = (message) => {
        setNotificationVisible(true);
        setNotificationMessage(message);

        setTimeout(() => {
            setNotificationVisible(false);
            setNotificationMessage("");
        }, 4000);
    }

    const blurEntirePage = () => {

        setPageLoading(true)

    }
    const unblurEntirePage = () => {

        setPageLoading(false);
    }

    /**
     * 
     * @param store 
     * @returns a boolean indicating whether or not the store is CoD-mode-only
     */
    const setupStore = async (store) => {
        try {
            const queryParameters = getQueryParametersFromUrl(window.location.href);
            const {kioskMode} = setupKioskMode(queryParameters);

            localStorage.setItem(localStorageItemMap.store, JSON.stringify(store));
            console.log("STORE", store);
            _setStore(store);

            // set terminalId full string
            const terminalId = setupTerminalId(queryParameters);
            console.log("TERMINAL ID", terminalId);
            setStoreTerminalId(`${store.StoreCode}-${terminalId}`)

            setDataApiUrl(queryParameters);
            setImageApiUrl(queryParameters);
            setIFrameLocalStorageItem(queryParameters);
            setForcePngLocalStorageItem(queryParameters);
            setDebugLocalStorageItem(queryParameters);
            setupQuickAdd(queryParameters);
            setDisableLangChangeLocalStorageItem(queryParameters);
            
            setLocalPrintLocalStorageItem(queryParameters);
            setBarcodeFormatLocalStorageItem(queryParameters);
            setCollectionNoteLocalStorageItem(queryParameters);
            setConfirmedOrdersLocalStorageItem(queryParameters);
            setDisableStorePickupLocalStorageItem(queryParameters);

            let locale = store.Default_Locale;
            let localesForStore = locales;
            const localeLocalStorageItem = localStorage.getItem(localStorageItemMap.locale);

            if (localeLocalStorageItem != null && localeLocalStorageItem != "") {
                locale = localeLocalStorageItem;
            }

            if(store.Locales != null) {
                locale = store.Default_Locale;
                localesForStore = store.Locales;
            }

            setLocale(locale);
            setAvailableLocales(localesForStore);
            const changeLocaleResult = await _changeLocale(locale, store.StoreCode, kioskMode);

            const storeJson = changeLocaleResult.storeJson;
            
            // Handle CoD Mode only stores
            let codMode = false;
            let codDefaultProduct = null;

            if(storeJson.CoDDefaultProduct != null && storeJson.CoDMode != null) {
                codMode = storeJson.CoDMode;
                // Set theme to dark
                if(codMode === true) {
                    setTheme("theme-dark");
                    codDefaultProduct = storeJson.CoDDefaultProduct;
                }
            }

            return {codMode, codDefaultProduct};
        }
        catch (error) {
            console.log(error)
            return {codMode: null, codDefaultProduct: null};
        }
    }

    const setDisplayScreensaverModalTimeout = useCallback((timeouts: any) => {
        if (pageRef.current != null) {
            const timeoutDuration = parseInt(timeouts.screensaverModalTimeout) || 20000;
            if(modalTimeout.current) clearTimeout(modalTimeout.current);
            modalTimeout.current = setTimeout(() => {
                showScreensaverModal(timeouts);
            }, timeoutDuration);
        }
    }, []);
    
    const setDisplayScreensaverVideoTimeout = (timeouts: any) => {
        const timeoutDuration = parseInt(timeouts.screensaverVideoTimeout) || 60000;
        if(videoTimeout.current) clearTimeout(videoTimeout.current);
        videoTimeout.current = setTimeout(() => {
            showScreensaverVideo();
        }, timeoutDuration);
    }

    const showScreensaverModal = (storeJson: any) => {
        setScreensaverModalVisible(true);
        setDisplayScreensaverVideoTimeout(storeJson);
    }

    const showScreensaverVideo = () => {
        clearTimeout(modalTimeout.current);
        _setScreensaverVideoVisible(true);
        let eventCategory = "screensaver_shown";
        let eventAction = window.location.pathname
        let eventLabel = "Abandoned at: '" + window.location.pathname +"'";
        const store = JSON.parse(localStorage.getItem(localStorageItemMap.store));

        sendEvent(eventCategory, eventAction, eventLabel, store.StoreCode, {
            terminal_id: storeTerminalId
        });

        if (localStorage.getItem(localStorageItemMap.sessionHash)) {
            localStorage.removeItem(localStorageItemMap.sessionHash);

            // event sometimes happens before screensaver shown
            setTimeout(() => {
                sendEvent("reset_session", `erase_existing_sesion_hash`, `Erase Existing Session Hash`, store.StoreCode, {
                    session_hash: 'no_current_session'
                });
            }, 1)
        }
    }

    const hideScreensaver = useCallback(() => {
        const screensaverModalTimeout = storeJsonRef.current.ScreensaverModalTimeout;
        const screensaverVideoTimeout = storeJsonRef.current.ScreensaverVideoTimeout;
        const timeouts = { screensaverModalTimeout, screensaverVideoTimeout };
        clearTimeout(modalTimeout.current);
        clearTimeout(videoTimeout.current);
        setDisplayScreensaverModalTimeout(timeouts);
        setScreensaverModalVisible(false);

        if (screensaverVideoVisibleRef.current === true) {
            _setScreensaverVideoVisible(false);
            setNavbarVisible(true);
            setZoom(false);
            _changeLocale(storeRef.current.Default_Locale, storeRef.current.StoreCode, kioskMode);
            const history = historyHook;

            // Handle redirection in CoD mode only stores
            let redirectUrl = "/";
            const isCoDMode = store_json && store_json.CoDMode;
    
            if(isCoDMode != null && isCoDMode === true && store_json.CoDDefaultProduct != null) {
                redirectUrl = `/product-designer/${store_json.CoDDefaultProduct}?mode=product`;
            }
            history.push(redirectUrl);
            _clearCart();
        }
    }, [store_json, kioskMode, setDisplayScreensaverModalTimeout]);

    const detectUserAction = useCallback((event) => {
        const isKiosk = getKioskLocalStorageItem();
        if (isKiosk == true) {
            setTimeout(() => hideScreensaver(), 100);
        }
    }, [hideScreensaver]);

    const setupEventListeners = useCallback(() => {
        window.addEventListener("click", detectUserAction);
        window.addEventListener('touchend', detectUserAction);
        window.addEventListener('keyup', detectUserAction);
    },[detectUserAction]);

    const removeEventListeners = useCallback(() => {
        window.removeEventListener("click", detectUserAction);
        window.removeEventListener('touchend', detectUserAction);
        window.removeEventListener('keyup', detectUserAction);
    }, [detectUserAction]);
    
    useEffect(() => {
        if(kioskMode == true && store_json != null && store_json.Screensaver != null) {
            setupEventListeners();
            const screensaverModalTimeout = store_json.ScreensaverModalTimeout;
            const screensaverVideoTimeout = store_json.ScreensaverVideoTimeout;

            const timeouts = { screensaverModalTimeout, screensaverVideoTimeout };
            setDisplayScreensaverModalTimeout(timeouts);
        }

        return () => { removeEventListeners()};
    }, [store_json, kioskMode, setupEventListeners, removeEventListeners, setDisplayScreensaverModalTimeout])


    const toggleTheme = () => {
        if (theme == "theme-light") {
            setTheme("theme-dark");
        } else {
            setTheme("theme-light");
        }
    };

    return (

        <React.Fragment>

                <TranslateContext.Provider
                    value={{
                        t: _t,
                        locale: locale,
                        availableLocales: availableLocales,
                        changeLocale: _changeLocale
                    }}
                >
                    <CartContext.Provider
                        value={{
                            cart: cartState,
                            addCartItem: _addCartItem,
                            removeCartItem: _removeCartItem,
                            updateCartItemQuantity: _updateCartItemQuantity,
                            updateCartItem: _updateCartItem,
                            clearCart: _clearCart,
                            localiseCartItemProductDisplayNames: _localiseCartItemProductDisplayNames
                        }}
                    >
                        <ErrorBoundary>
                            <Meta />

                            {(process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT == 'dev' || process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT == 'prod') && <noscript dangerouslySetInnerHTML={{
                                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.REACT_APP_ANALYTICS_ID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
                            }}></noscript>
                            }

                            {process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT == 'dev' && <DevPasswordOverlay kioskMode={kioskMode}/>}

                            {showLoader && <LoaderForBlurEffect timeout={200} />}

                            <Page ref={pageRef} zoomEffect={zoom} setShowLoader={setShowLoader} showLoader={showLoader} pageLoading={pageLoading} detectUserAction={detectUserAction} theme={theme}>

                                { navbarVisible == true &&
                                    <Navbar
                                        storeId={store.StoreCode}
                                        showLocalisationModal={showLocalisationModal}
                                        hideLocalisationModal={hideLocalisationModal}
                                        localisationModalVisible={localisationModalVisible}
                                        disableLangChange={disableLangChange}
                                        storeJSON={store_json}
                                        store={store}
                                        yrApiUrls={{dataApiBaseUrl, imageApiBaseUrl}}
                                        disableStorePickup={disableStorePickup}
                                        kioskMode={kioskMode}
                                        quickAdd={quickAdd}
                                        updateDisableStorePickupLocalStorageItem={updateDisableStorePickupLocalStorageItem}
                                        updateQuickAddLocalStorageItem={updateQuickAddLocalStorageItem}
                                        theme={theme}
                                        showThemeSwitcher={showThemeSwitcher}
                                        toggleTheme={toggleTheme}
                                        getStoreMap={getStoreMap}
                                        storeTerminalId={storeTerminalId}
                                    />
                                }
                                <React.Suspense fallback={<ProgressBar />}>
                                    <Switch>
                                        <Route path="/" exact>
                                            <HomePage
                                                store_id={store.StoreCode}
                                                store_json={store_json}
                                                navbarVisible={navbarVisible}
                                                showNavbar={showNavbar}
                                                disableStorePickup={disableStorePickup}
                                                toggleTheme={toggleTheme}
                                                storeTerminalId={storeTerminalId}
                                                {...props} 
                                            />
                                        </Route>

                                        <Route path='/product-designer/:recipe' render={(props) => {
                                            return (
                                                <ProductPage
                                                    hideNavbar={hideNavbar}
                                                    showNavbar={showNavbar}
                                                    showNotification={showNotification}
                                                    blur={blurEntirePage}
                                                    unblur={unblurEntirePage}
                                                    notificationVisible={notificationVisible}
                                                    notificationMessage={notificationMessage}
                                                    setupKioskMode={setupKioskMode}
                                                    yrApiUrls={{dataApiBaseUrl, imageApiBaseUrl}}
                                                    setForcePngLocalStorageItem={setForcePngLocalStorageItem}
                                                    setZoom={setZoom}
                                                    zoom={zoom}
                                                    key={props.match.params.recipe}
                                                    toggleTheme={toggleTheme}
                                                    storeJSON={store_json}
                                                    getStoreMap={getStoreMap}
                                                    {...props} />)
                                        }} />

                                        <Route path="/basket" exact>
                                            <CartPage
                                                storeId={store.StoreCode}
                                                storeJSON={store_json}
                                                yrApiUrls={{dataApiBaseUrl, imageApiBaseUrl}}
                                                locale={locale}
                                                quickAdd={quickAdd}
                                                disableStorePickup={disableStorePickup}
                                            />
                                        </Route>

                                        <Route path="/checkout" exact>
                                            <CheckoutPage
                                                store_id={store.StoreCode}
                                                default_locale={store.Default_Locale}
                                                storeJSON={store_json}
                                                shipping_countries={shippingCountries}
                                                collectionNote={collectionNote}
                                                confirmedOrders={confirmedOrders} 
                                                disableStorePickup={disableStorePickup}
                                            />
                                        </Route>
                                        <Route path="/order-confirmation" exact>
                                            <OrderConfirmationPage store_json={store_json} {...props} storeTerminalId={storeTerminalId} />
                                        </Route>
                                        <Route path="/privacy-policy" exact>
                                            <PrivacyPolicyPage store_json={store_json} history={historyHook}/>
                                        </Route>
                                        <Route path="/error" render={(props) => {
                                            return (
                                                <ErrorBoundary {...props} />
                                            )
                                        }} />
                                    </Switch>
                                </React.Suspense>
                                {screensaverModalVisible && <ScreensaverModal />}
                                {screensaverVideoVisible && store_json.Screensaver != null && <Screensaver videoUrl={store_json.Screensaver} />}
                            </Page>
                        </ErrorBoundary>

                    </CartContext.Provider>
                </TranslateContext.Provider>
        </React.Fragment >
    );
}

export default App;


