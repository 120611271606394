import React from "react";
import TranslateContext from "../../providers/TranslateContext";
import styles from "../../styles/HiddenSettingsModalStyles.module.scss"

class HiddenSettingsModal extends React.Component<any, any> {

    modalOverlayRef: any;
    hiddenSettingsModalRef: any;
    touching: boolean;
    startX: number;

    constructor(props) {
        super(props);
        this.hiddenSettingsModalRef = React.createRef();
        this.modalOverlayRef = React.createRef();
        this.state = {
            containerClassName: styles[`navigation-drawer-container`],
            containerStyle: {},
            changeProductStyle: {},
            disableStorePickupChecked: false,
            quickAddChecked: false
        };
    }

    componentDidMount() {
        this.setState({disableStorePickupChecked: this.props.disableStorePickup, quickAddChecked: this.props.quickAdd});
    }


    handleQuickAddChange = (event) => {
        const newCheckedState = !this.state.quickAddChecked;
        this.props.updateQuickAddLocalStorageItem(newCheckedState);
        this.setState({quickAddChecked: newCheckedState})
    }

    handleDisableStoreChange = (event) => {
        const newCheckedState = !this.state.disableStorePickupChecked;
        this.props.updateDisableStorePickupLocalStorageItem(newCheckedState);
        this.setState({disableStorePickupChecked: newCheckedState})
    }

    render() {
        return (
            <React.Fragment>
                <TranslateContext.Consumer>
                    {({ t }) => (
                        <React.Fragment>
                            <div ref={this.modalOverlayRef} className={styles[`hidden-settings-modal-overlay`]} onClick={this.props.closeModal}> </div>
                            <div ref={this.hiddenSettingsModalRef} className={styles[`hidden-settings-modal-container`]}>
                                <div className={styles[`hidden-settings-modal`]}>
                                    <div className={styles["hidden-settings-modal-top-bar"]}>
                                        <h3 className={styles["hidden-settings-modal-header"]}>{t("hiddenSettingsModal.heading")}</h3>
                                        <img className={styles[`close-modal-icon`]} src="/assets/icons/close-white-32px.svg" alt="Close" onClick={this.props.closeModal}/>
                                    </div>   
                                    <div className={styles["hidden-settings-modal-content"]}>
                                        <div className={styles["setting-line"]}>
                                            <label className={styles["setting-label"]} htmlFor="quickAdd">{t("hiddenSettingsModal.quick_add")}</label>
                                            <label className={styles["checkbox-container"]}>
                                                <input type="checkbox" id="quickAddCheckbox" value="quickAdd"className={styles["setting-checkbox"]} checked={this.state.quickAddChecked} onChange={(event) => this.handleQuickAddChange(event)} />
                                                <span className={styles["checkbox-content"]}></span>
                                            </label>
                                        </div>
                                        <hr></hr>
                                        <div className={styles["setting-line"]}>
                                            <label className={styles["setting-label"]} htmlFor="disableStorePickup">{t("hiddenSettingsModal.disable_store_pickup")}</label>
                                            <label className={styles["checkbox-container"]}>
                                                <input type="checkbox" id="disableStorePickupCheckbox" value="disableStorePickup"className={styles["setting-checkbox"]} checked={this.state.disableStorePickupChecked} onChange={(event) => this.handleDisableStoreChange(event)} />
                                                <span className={styles["checkbox-content"]}></span>
                                            </label>
                                        </div>
                                    </div>                         
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </TranslateContext.Consumer>
            </React.Fragment>
        )
    }
}

export default HiddenSettingsModal;
