import CCO from "../../classes/cco";
import { jsonApiRepository } from "../../repositories/json-api-repository";
import { areaHelpers } from "./area-helpers";
import { getProductData, getProductDetails, getROCFromProductIndex, getROCsForBlankProduct, getViewThumbnailImages } from "./get-product-data";
import { getYrHotspotCoords, getXCoord, getYCoord } from "./hotspot-helpers";
import { ROCHelpers } from "./roc-helpers";
import { getProductImage, getProductImageForCod, getViewThumbnailImagesForCod,  getProductImageSource , calculateImageDimensions} from "../../helpers/product-page/get-product-data";
import { localStorageItemMap } from "../local-storage-parameter-map";


export const createColourSwatches = (product) => {

    const colours = product.SubCategory;
    let coloursArray = [];
    for (let item of colours) {
        coloursArray.push({hex: item.ColourHex, id: item.ColourID});
    }
    return coloursArray;
}

export const getRecipeOfROC = (roc, recipes) => {

    const recipe = recipes.find(recipe => recipe.roc === roc.ID);
    return recipe;

}

export const getRocFromRocs = (roc, rocs) => {

    return rocs.find(route => roc.ID === route.ID);
}

export const getViewForNewROC = (roc, view) => {

    const rocViews = roc.View;
    const newViewId = `${roc.ID}.${view.ID.split('.')[1]}`;
    const newView = rocViews.find((rocView) => newViewId === rocView.ID);
    return { newView, newViewId };
}

export const createDefaultRecipe = (productIndex, currentROC) => {

    const views = currentROC.View;
    let customisation = [];

    for (let view of views) {
        const viewAreas = view.Area;

        for (let area of viewAreas) {

            customisation.push(area.DefaultCCO)
        }
    }
    const recipe = {
        "recipe": {
            "product": { productIndex, "sku": "" },
            customisation
        }
    }
    return recipe;
}

export const createDefaultRecipeForBlankProduct = async (dataApiBaseUrl: string, store_id: string, locale: string, productIndex: string) => {
    let customisation = [];

    let recipe = {
        "recipe": {
            "product": { productIndex, "sku": "" },
            customisation
        }
    };
    const rocs = await getROCsForBlankProduct(dataApiBaseUrl, store_id, locale, productIndex, recipe);

    const views = rocs[0].View;

    for (let view of views) {
        const viewAreas = view.Area;

        for (let area of viewAreas) {

            customisation.push(area.DefaultCCO)
        }
    }

    recipe.recipe.customisation = customisation;

    return recipe;
}

export const findRecipeIndexToUpdate = (rocId, recipes) => {

    const index = recipes.findIndex(element => element.roc === rocId);
    return index;

}

export const createRecipesForRocs = (rocs, currentROC, currentRecipe) => {

    const recipesPerROC: any = [];

    for (let roc of rocs) {

        const recipePerROC = {
            "roc": roc.ID,
            "recipe": null
        }

        if (currentROC.ID == roc.ID) {

            recipePerROC.recipe = currentRecipe.recipe;
        }
        recipesPerROC.push(recipePerROC);
    }
    return recipesPerROC;
}

export const updateRecipesForRocs = (rocs, productRecipes) => {

    const recipesPerROC: any = [];

    for (let roc of rocs) {
        const recipe = productRecipes.find(item => {

            return item.roc === roc.ID
        });

        const recipeToBeAdded = recipe ? recipe.recipe : null;
        const recipePerROC = {
            "roc": roc.ID,
            "recipe": recipeToBeAdded
        }
        recipesPerROC.push(recipePerROC);
    }

    return recipesPerROC;
}

export const optionContainsText = (option) => {
    
    let doesOptionContainText = false;

    if ((option.DefaultText01 != null && option.DefaultText01 != "") || (option.DefaultText02 != null && option.DefaultText02 != "")) {
        doesOptionContainText = true;
    }

    return doesOptionContainText;
}

export const getTextInputHotspots = (text, area) => {

    let hotspot1, hotspot2, result = [];
    const text1 = text.text1 ? text.text1 : area.option1.DefaultText01;
    const text2 = text.text2 ? text.text2 : area.option1.DefaultText02;

    if (area.option2.Pos01 != "") {

        hotspot1 = {
            "hotspotID": '1',
            "charLimit": area.option1.CharLimit01,
            "text": text1,
            "typedText": text1,
            "previousTypedText": text1,
            "position": area.option2.Pos01,
            "keyboard": area.option1.Keyboard01 ? area.option1.Keyboard01 : "Full"
        }
        result.push(hotspot1);
    }

    if (area.option2.Pos02 != "") {

        hotspot2 = {
            "hotspotID": '2',
            "charLimit": area.option1.CharLimit02,
            "text": text2,
            "typedText": text2,
            "previousTypedText": text2,
            "position": area.option2.Pos02,
            "keyboard": area.option1.Keyboard02 ? area.option1.Keyboard02 : "Full"
        }
        result.push(hotspot2);
    }

    return result;
}

export const updateDesignLinkText = (previousLink, text, option) => {

    const regex = new RegExp(`${option}=(.*)`);
    const matches = previousLink.match(regex);
    let newLink;

    if (matches) {

        newLink = previousLink.replace(regex, `${option}=${text}`);
    }
    else {
        newLink = `${previousLink}&${option}=${text}`;
    }
    return newLink;
}

export const getDesignLink = (designLink, text1, text2) => {

    if (text1) {
        text1 = encodeURIComponent(text1);
        designLink += `&text1=${text1}`;
    }
    if (text2) {
        text2 = encodeURIComponent(text2);
        designLink += `&text2=${text2}`;
    }
    return designLink;
}

export const checkRecipeAgainstNewROCs = (recipe, roc) => {

    const customisation = recipe.recipe.customisation;
    const validAreas: any = areaHelpers.getAllAreasOfCurrentRoc(roc);

    let validRecipe = [];

    for (let i = 0; i < validAreas.length; i++) {

        const validCCO = customisation.find(element => element.area === validAreas[i].ID);

        if (validCCO) {

            if (validAreas[i].CCOisValid) {

                validRecipe.push(validCCO);
            }
            else {
                const defaultCCO = validAreas[i].DefaultCCO;
                validRecipe.push(defaultCCO);
            }
        }
    }
    recipe.recipe.customisation = validRecipe;
    return recipe;
}


export const getTextFromRecipe = (cco, option) => {

    const result: any = {};
    const defaultText01 = option.DefaultText01;
    const defaultText02 = option.DefaultText02;


    if (cco && cco.text1 && cco.text1 !== defaultText01) {

        result.text1 = cco.text1;
    }

    if (cco && cco.text2 && cco.text2 !== defaultText02) {

        result.text2 = cco.text2;
    }

    return result;
}
export const updateTextOptions = (nonDefaultText, option) => {

    const text1 = Object.keys(nonDefaultText).length > 0 ? nonDefaultText.text1 : option.DefaultText01;
    const text2 = Object.keys(nonDefaultText).length > 0 ? nonDefaultText.text2 : option.DefaultText02;
    return { text1, text2 };
}

export const updateRecipe = (options) => {

    const {
        currentROC, currentCustomisationArea, text, productRecipes, productColour
    } = options;

    const roc = currentROC.ID;
    const area = currentCustomisationArea;
    const text1 = text.text1;
    const text2 = text.text2;
    const recipeForROCIndex = productRecipes.findIndex(recipe => recipe.roc === roc);
    const recipeContainer: any = { ...productRecipes[recipeForROCIndex] };
    let recipe: any = { ...recipeContainer.recipe };
    const [option1, option2, option3] = [area.option1, area.option2, area.option3];
  
    let isDefault1, isDefault2;

    if (text1 == "" || !text1) {
        isDefault1 = "1";
    }
    else {
        isDefault1 = option1.DefaultText01 === text1 ? "1" : "0";
    }

    if (text2 == "" || !text2) {
        isDefault2 = "1";
    }
    else {
        isDefault2 = option1.DefaultText02 === text2 ? "1" : "0";
    }

    const ccoArea = area.ID;
    const position = area.PositionCode;
    const texture = area.Texture;
    const ccoOption1 = option1.ID;
    const ccoOption2 = option2 ? option2.ID : "";
    const ccoOption3 = option3 ? option3.ID : "";
    const ccoText1 = text1 ? text1 : option1.DefaultText01;
    const ccoText2 = text2 ? text2 : option1.DefaultText02;
    const ccoObject = new CCO(ccoArea, position, texture, ccoOption1, ccoOption2, ccoOption3, ccoText1, ccoText2, isDefault1, isDefault2);
    const customisation = [...recipe.customisation];

    let CCOIndex = customisation.findIndex(cco => cco.area === area.ID);

    if (CCOIndex === -1) {

        customisation.push(ccoObject);
    }
    else {

        customisation[CCOIndex] = { ...ccoObject };
    }

    recipe.product.ColourHex = productColour; 
    recipe.customisation = customisation;

    recipeContainer.recipe = recipe;
    productRecipes.splice([recipeForROCIndex], 1);
    const updatedProductRecipes = [];

    for (let recipe of productRecipes) {
        updatedProductRecipes.push(recipe);
    }
    updatedProductRecipes.push(recipeContainer);

    return updatedProductRecipes;
}

export const getProductColour = (colours, productIndex) => {

    const currentProduct: any = colours.find((variant: any) => variant.Selected == true);
    return currentProduct.ColourHex;
}

export const getOptionThree = (area, suboptions) => {

    let option3;
    const existingOption3 = area.option3;

    if (suboptions && suboptions.length > 0) {

        if (existingOption3 && existingOption3.DisplayName1 != "") {
            option3 = suboptions.find(element => element.DisplayName1 == existingOption3.DisplayName1) || suboptions[0];
        }
        else if (existingOption3) {
            option3 = suboptions.find(element => element.Selected == true) || suboptions.find(element => element.ID == existingOption3.ID) || suboptions[0];
        }
        else {
            option3 = suboptions[0];
        }
    }
    return option3;
}

export const getDesign = async (options, locale) => {

    const { dataApiBaseUrl, imageApiBaseUrl, storeId, recipe, viewID } = options;
    const productData: any = await getProductData(dataApiBaseUrl, imageApiBaseUrl, storeId, recipe, viewID, locale);

    //Get Routes of Customisation & Product Details for this product       
    const rocs = productData.productRocsJSON.ROC;
    const productDetails = productData.productDetailsJSON;
    const productIndex = recipe.recipe.product.productIndex;
    const { imageWidth, imageHeight } = calculateImageDimensions();

    //Get selected ROC of recipe
    let currentROC = ROCHelpers.getSelectedROC(recipe.recipe, rocs);
    const optionArea = currentROC.View[0].Area[0].ID;
    const customisation = recipe.recipe.customisation;
    const initialOption = customisation.find(element => optionArea == element.area);

    // Get product options
    const productOptions = await jsonApiRepository.getProductOptions(dataApiBaseUrl, storeId, productIndex, recipe, locale);
    const selectedProductOption = getSelectedProductOption(productOptions);

    //Create recipes for ROCs
    const recipesPerROC = createRecipesForRocs(rocs, currentROC, recipe);
    //Create colour swatches for product
    const colours = createColourSwatches(productDetails);

    //Create views
    const views = currentROC.View;
    let viewThumbnails = null;
    if(currentROC.PdpId == "2") {
        viewThumbnails = await getViewThumbnailImagesForCod(imageApiBaseUrl, storeId, views, productIndex, currentROC.ID, recipe, locale);
    }
    else {
        viewThumbnails = await getViewThumbnailImages(imageApiBaseUrl, storeId, views, productIndex, currentROC.ID, recipe, locale);
    }
    
    const productColours: [] = productDetails.SubCategory;
    let productColour = getProductColour(productColours, productIndex);

    let backgroundImage = null;
    let foregroundImage = null;
    let newProductSubCategories = null;

    console.log("product details", productColours, productDetails, currentROC);

    if(currentROC.PdpId == "2") {
        backgroundImage =  await getProductImageForCod( imageApiBaseUrl, storeId, 
            productDetails.SubCategory[0].VendorReference, currentROC.ID,
        currentROC.View[0].ID, true, "en_US", 1);

        //must re-call foreground image with updated recipe
        foregroundImage =  await getProductImageForCod( imageApiBaseUrl, storeId, 
           productDetails.SubCategory[0].VendorReference, currentROC.ID,
            currentROC.View[0].ID, true, "en_US", 0, 1, recipe);
            newProductSubCategories = productDetails.SubCategory.map((product) => {
            if(product.ColourHex == productColour){
                product.Selected = true;
            }
            else {
                product.Selected = false;
            }

            if(product.Selected == true) {
                productColour = product.ColourHex;
            }

            return product;
        })
    }

    if(newProductSubCategories != null) {
        productDetails.SubCategory = [...newProductSubCategories];
    }
        
    const design1 = {
      productIndex,
      productDetails,
      productOptions,
      selectedProductOption,
      availableProductColours: colours,
      productImage: productData.productImage,
      availableROCs: rocs,
      productRecipes: recipesPerROC,
      currentROC,
      currentProductViews: views,
      currentViewDisplayed: views[0],
      initialOption,
      viewThumbnails,
      productColour,
      backgroundImage,
      foregroundImage,
    };

    console.info(design1);

    return design1;
}

export const getProductDataOnLanguageChange = async (options, locale) => {
    const { dataApiBaseUrl, storeId, recipe, roc } = options;
    const productIndex = recipe.recipe.product.productIndex;
    const productDetailsPromise = getProductDetails(dataApiBaseUrl, storeId, productIndex, roc, locale, recipe);
    const productOptionsPromise = jsonApiRepository.getProductOptions(dataApiBaseUrl, storeId, productIndex, recipe, locale);
    const getROCPromise = getROCFromProductIndex(dataApiBaseUrl, storeId, productIndex, recipe, locale);
    const [productDetailsResponse, productOptions, rocsResponse] = await Promise.all([productDetailsPromise, productOptionsPromise, getROCPromise]);
    const productDetailsJSONPromise = productDetailsResponse.json();
    const rocJSONPromise = rocsResponse.json();
    const [productDetailsResult, rocsResult] = await Promise.all([productDetailsJSONPromise, rocJSONPromise]);
    const productDetails = productDetailsResult.Result;
    const rocs = rocsResult.Result.ROC;
    let currentROC = ROCHelpers.getSelectedROC(recipe.recipe, rocs);
    const optionArea = currentROC.View[0].Area[0].ID;
    const customisation = recipe.recipe.customisation;
    const initialOption = customisation.find(element => optionArea == element.area);
    const selectedProductOption = getSelectedProductOption(productOptions);

    const state = {
        productDetails,
        productOptions,
        selectedProductOption,
        availableROCs: rocs,
        currentROC,
        initialOption
    }
    return state;
}

export const getSelectedProductOption = (productOptions) => {

    let selectedProductOption = {
        DisplayName: "",
        Name: "",
        OutOfStock: false,
        SKU: "",
        UPC: "",
    };

    if (productOptions.length == 1) {
        selectedProductOption = productOptions[0];
    }
    return selectedProductOption;
}


export const updateDesignLinkWithText = (designLink, text1, text2) => {
    designLink += text1 ? `&text1=${encodeURIComponent(text1)}` : '';
    designLink += text2 ? `&text2=${encodeURIComponent(text2)}` : '';
    return designLink
}

export const getDesignLinkFromOptions = (currentCustomisationArea, option, textOption, text, storeId) => {

    let designLink = currentCustomisationArea.option2.DesignLink;
    let option3: any = currentCustomisationArea.option3;
    if (option3 && option3.DesignLink != null && option3.DesignLink != "") {
        designLink = option3.DesignLink;
    }

    if (textOption) designLink = getTextAndUpdateLink(text, option, designLink);

    if (option3) {
        designLink += `&opt3=${option3.ID}`;
    }
    designLink += `&store=${storeId}`;
    return designLink;
}

export const getTextAndUpdateLink = (text, option, designLink) => {
    const { text1, text2 } = updateTextOptions(text, option);
    designLink = updateDesignLinkWithText(designLink, text1, text2);
    return designLink;
}

export const createProductThumbnails = (cartItemId, thumbnails) => {

    let productThumbnails: any = {};
    const addedToCartProductThumbnails = localStorage.getItem(localStorageItemMap.productThumbnails);
    if (addedToCartProductThumbnails) {
        productThumbnails = { ...JSON.parse(addedToCartProductThumbnails) };
    }
    let newThumbnails = JSON.stringify(thumbnails);
    productThumbnails[cartItemId] = newThumbnails;

    return productThumbnails;
}

export const createProductThumbnailsForCod = (cartItemId, thumbnails) => {

    let productThumbnails: any = {};
    const addedToCartProductThumbnails = localStorage.getItem(localStorageItemMap.productThumbnails);
    if (addedToCartProductThumbnails) {
        productThumbnails = { ...JSON.parse(addedToCartProductThumbnails) };
    }
    let newThumbnails = JSON.stringify(thumbnails);
    productThumbnails[cartItemId] = newThumbnails;

    return productThumbnails;
}
export const getRocsForNewRecipe = (recipe: any, productIndex: string, currentROC: string) => {

    let defaultRecipe = null, updatedRecipe;

    if (recipe.recipe == null) {

        defaultRecipe = createDefaultRecipe(productIndex, currentROC);
        updatedRecipe = checkRecipeAgainstNewROCs(defaultRecipe, currentROC);
    }
    else {
        const recipeObject = { "recipe": recipe.recipe };
        updatedRecipe = checkRecipeAgainstNewROCs(recipeObject, currentROC);
    }

    if (updatedRecipe.recipe.product.productIndex != productIndex) {
        updatedRecipe.recipe.product.productIndex = productIndex;
    }
    return updatedRecipe;
}

export const getProductOptions = (productOptions, preselectedProductOption) => {

    // Set default selectedProductOption
    let selectedProductOption = {
        DisplayName: "",
        Name: "",
        OutOfStock: false,
        SKU: "",
        UPC: "",
    };

    if (productOptions.length == 1) {
        selectedProductOption = productOptions[0];

    }
    else {
        // Check to see if the currently selected product option exists in the new product options. If it does, select it
        for (let i = 0; i < productOptions.length; i++) {
            if (productOptions[i].Name === preselectedProductOption.Name && !productOptions[i].OutOfStock) {
                selectedProductOption = productOptions[i];
                break;
            }
        }
    }

    return selectedProductOption;

}

export const getZoomingFactors = (dimensions, pageDimensions, area) => {

    const coords = getYrHotspotCoords(area);
    const containerWidth = dimensions.width;
    const containerHeight = dimensions.height;
    const hotspotW = coords.width * containerWidth;
    const hotspotH = coords.height * containerHeight;
    const hotspotX = getXCoord(coords.x, containerWidth, hotspotW);
    const hotspotY = getYCoord(coords.y, containerHeight, hotspotH);
    const imageCentrePointX = (dimensions.x + dimensions.width) / 2;
    const imageCentrePointY = (dimensions.y + dimensions.height) / 2;
    const hotspotCentreX = hotspotX + (hotspotW / 2);
    const hotspotCentreY = hotspotY + (hotspotH / 2);
    const translateX = imageCentrePointX - hotspotCentreX;
    const translateY = hotspotCentreY - imageCentrePointY;
    const scale = pageDimensions.width / hotspotW;
    const zoomDuration = 500;
    return { zoomParams: { translateX, translateY, scale, zoomDuration } }
}