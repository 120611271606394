import React, {useState, useEffect} from "react";
import styles from "../../../styles/ChangeProductSkeletonLoader.module.scss";
import skeletonLoaderStyles from "../../../styles/SkeletonLoader.module.scss";
import { ChangeProductSkeletonLoaderThumbnail } from "./ChangeProductSkeletonLoaderThumnail";

const NUM_FAKE_PRODUCTS = 10;

export const ChangeProductSkeletonLoader: React.FunctionComponent<{}> = ({ }) => {
    
    const [fakeProducts, setFakeProducts] = useState([]);

    useEffect(() => {
        const fakeProducts = [];
        for (let i = 0; i < NUM_FAKE_PRODUCTS; i++) {
            fakeProducts.push({
                index: i
            });
        }
        setFakeProducts(fakeProducts);
    }, [])
    
    return (
        <React.Fragment>
            <div className={styles[`change-product-division`]}>
                <div className={styles[`change-product-division-title`] + " " + skeletonLoaderStyles[`loading`]}></div>
                <div className={styles[`change-product-division-products`]}>
                    {fakeProducts.map((fakeProduct, index) => {
                        return (
                            <ChangeProductSkeletonLoaderThumbnail key={index} style={null} />
                        )
                    })}
                </div>
            </div>
        </React.Fragment>
    )
}