import React from "react";
import styles from "../../../styles/ChangeProductModalThumbnail.module.scss"
import { ChangeProductSkeletonLoaderThumbnail } from "./ChangeProductSkeletonLoaderThumnail";
import { sendEvent } from "../../../helpers/analytics/send-event";
import { localStorageItemMap } from "../../../helpers/local-storage-parameter-map";
import { generateRandomHash } from "../../../helpers/session";

class ChangeProductModalThumbnail extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = { loaded: false, componentStyle: {}, loaderStyle: {} };
    }

    componentDidMount() {
       
        const componentStyle = {
            position: "absolute",
            top: 0,
            left: 0,
        }

        const loaderStyle = {
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10
        }

        this.setState({
            componentStyle,
            loaderStyle
        })
    }

    onLoad = () => {
        this.setState({ loaded: true });
    };

    changeProduct = (event) => {
        const sessionHash = localStorage.getItem(localStorageItemMap.sessionHash);
        const store = JSON.parse(localStorage.getItem(localStorageItemMap.store));
        let eventCategory = "change_product_click";
        let eventAction = this.props.id;
        let eventLabel = event.currentTarget.id;
        
        sendEvent(eventCategory, eventAction, eventLabel, store.StoreCode);

        if (!sessionHash) {
            localStorage.setItem(localStorageItemMap.sessionHash, generateRandomHash(32));
            setTimeout(() => {
                
                eventCategory = "first_interaction";
                eventAction = `dropdown`;
                eventLabel = `First Interaction From Dropdown`;

                sendEvent(eventCategory, eventAction, eventLabel, store.StoreCode, {
                    session_hash: localStorage.getItem(localStorageItemMap.sessionHash),
                    terminal_id: this.props.storeTerminalId
                });
            }, 1);
        }
            
        this.props.changeProduct(this.props.product);
    }

    buildThumbLinkUrl = () => {
        let url = `${this.props.imageApiBaseUrl}${this.props.product.ThumbLink}&format=PNG`;

        if (window.innerWidth < 1080) {
            url += `&width=96&height=96`;
        }
        else {
            url += `&width=144&height=144`;
        }

        return url;
    }

    render() {
        
        let divClassName = '';

        if (this.state.loaded === false) {
            divClassName += styles.itemHidden;
        }      

        return (
            <React.Fragment>
                <div id={`${this.props.id}-${this.props.product.DisplayName}`} className={styles[`change-product-modal-thumbnail-wrapper`]}>


                    {!this.state.loaded && <ChangeProductSkeletonLoaderThumbnail style={this.state.loaderStyle} />}
                    <div className={styles[`change-product-modal-thumbnail-container`]} style={this.state.componentStyle} id={`${this.props.product.DisplayName}-${this.props.product.Index}`} onClick={this.changeProduct}>
                        <div className={styles[`change-product-modal-thumbnail-image-container`]}>
                            <img src={this.buildThumbLinkUrl()} className={styles[`change-product-modal-thumbnail-image`]} onLoad={this.onLoad} />
                        </div>
                        {this.state.loaded &&
                            <div>
                                <div className={styles[`change-product-modal-thumbnail-line`] + " " + styles[`change-product-modal-thumbnail-line-1`]}>{this.props.product.DisplayName}</div>
                                <div className={styles[`change-product-modal-thumbnail-line`]}>{this.props.product.DisplayName2}</div>
                            </div>
                        }
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default ChangeProductModalThumbnail;