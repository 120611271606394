import { areaHelpers } from './area-helpers';


export const ROCHelpers = {


    getSelectedROC: (recipe, rocs) => {


        const recipeCustomisationAreas = areaHelpers.getRecipeCustomisationAreas(recipe);
        let selectedROC;

        for (let roc of rocs) {

            if (recipeCustomisationAreas[0].startsWith(roc.ID)) {
                selectedROC = roc;
            }
        }

        return selectedROC;
    },
    getRocIds: (rocs) => {

        let rocIDs = [];

        for (let roc of rocs) {
            rocIDs.push(roc.ID);
        }

        return rocIDs;
    },
    prepareRocDetailsForOrder(cart_items: any[]) {

        let updatedCartItems = [...cart_items];
        for(let cart_item of updatedCartItems) {

            const {Engine, ID, Name, PdpId} = cart_item.product.ROCDetails;
            const updatedRocDetails = {
                Engine,
                ID,
                Name,
                PdpId
            };
            cart_item.product.ROCDetails = updatedRocDetails;
        }
        
        return updatedCartItems;
    }
}
