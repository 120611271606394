interface ContentCustomisationObject {

    area: string;
    position: string;
    texture: string;
    option1: string;
    option2: string;
    option3: string;
    text1: string;
    text2: string;
    isDefault1: string;
    isDefault2: string;
}

class CCO implements ContentCustomisationObject {
    area: string;
    position: string;
    texture: string;
    option1: string;
    option2: string;
    option3: string;
    text1: string;
    text2: string;
    isDefault1: string;
    isDefault2: string;

    constructor(area: string, position: string, texture: string, option1: string, option2: string, option3: string, text1: string, text2: string, isDefault1: string, isDefault2: string) {

        this.area = area;
        this.position = position;
        this.texture = texture;
        this.option1 = option1;
        this.option2 = option2;
        this.option3 = option3;
        this.text1 = text1;
        this.text2 = text2;
        this.isDefault1 = isDefault1;
        this.isDefault2 = isDefault2;
    }
}

export default CCO;