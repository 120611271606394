import { jsonApiRepository } from "../../repositories/json-api-repository";
import { optionContainsText } from "../product-page/pdp-helpers";

export const updateRecipeWithDisplayNames = async (options) => {

    const { dataApiBaseUrl, storeId, recipe, locale } = options;
    const customisation = [...recipe.recipe.customisation];
    const customisationWithDisplayNames = [];

    for (let cco of customisation) {

        let width = 800;
        let height = 400;

        const configurationDetails = await jsonApiRepository.getConfigureDetailsWithRecipe(dataApiBaseUrl, storeId, cco.area, width, height, recipe, locale);
        const { option1, option2, option3 } = cco;

        const option1Details = configurationDetails.Result.ConfigureDetails.find(asset => {

            return asset.ID === option1;
        });

        if (configurationDetails.Result.ConfigureDetails.length > 1) {

            const option1DisplayName = option1Details.DisplayName1;
            cco.option1DisplayName = option1DisplayName;
            cco.defaultText1 = option1Details.DefaultText01;
            if (option1Details.DefaultText02 && option1Details.DefaultText02 != "") {

                cco.defaultText2 = option1Details.DefaultText02;
            }
        }

        let textOption = optionContainsText(option1Details);

        const options: any = [...option1Details.Options];
      
        let suboptions;
        let option2Details;
        if (options.length > 1) {
            option2Details = options.find((option, index) => {
                return index == option2;
            });


            if (option2Details.Pos01 === "") {
                textOption = false;
            }

            const option2DisplayName = option2Details ? option2Details.DisplayName1 : null;
            cco.option2DisplayName = option2DisplayName;
            suboptions = option2Details && option2Details.SubOptions ? [...option2Details.SubOptions] : [];
        }
        else {
            suboptions = {...options[0].SubOptions}
        }
        cco.textOption = textOption;

        if (suboptions && suboptions.length > 0) {
            const option3Details = suboptions.find(element => element.ID == option3);
            const option3DisplayName = option3Details ? option3Details.DisplayName1 : null;
            cco.option3DisplayName = option3DisplayName;
        }

        customisationWithDisplayNames.push(cco);
    }

    recipe.recipe.customisation = customisationWithDisplayNames;

    return recipe;

}
