import React from "react";
import { Link } from "react-router-dom";
import TranslateContext from "../../../providers/TranslateContext";
import styles from "../../../styles/NavigationDrawer.module.scss"
import ChangeProductContent from "../change-product/ChangeProductContent";

class NavigationDrawer extends React.Component<any, any> {

    navigationDrawerContainerRef: any;
    navigationDrawerRef: any;
    touching: boolean;
    startX: number;
    timeInterval: any;
    timer = 0;

    constructor(props) {
        super(props);
        this.navigationDrawerContainerRef = React.createRef();
        this.navigationDrawerRef = React.createRef();
        this.state = {
            containerClassName: styles[`navigation-drawer-container`],
            containerStyle: {},
            changeProductStyle: {}
        };
    }

    componentDidMount() {
        if (this.props.show == true) {
            this.updateNavigationDrawerContainerClassNames();
        }

        this.updateChangeProductStyle();
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.show != this.props.show) {
            this.updateNavigationDrawerContainerClassNames();
            this.scrollContentToTop();
        }
    }

    componentWillUnmount() {
        if(this.timeInterval != null) {
            clearInterval(this.timeInterval);
        }
    }

    updateNavigationDrawerContainerClassNames() {
        let containerClassName = styles[`navigation-drawer-container`];

        if (this.props.show == true) {
            containerClassName = styles[`navigation-drawer-container`] + " " + styles[`navigation-drawer-visible`]

        }
        else {
            containerClassName = styles[`navigation-drawer-container`] + " " + styles[`navigation-drawer-hidden`]
        }

        this.setState({
            containerClassName
        })
    }

    updateChangeProductStyle() {
        const changeProductStyle = {
            height: "auto",
            overflowY: "unset",
            position: "relative",
            top: "unset",
            left: "unset",
            paddingTop: window.innerWidth >= 1080 ? "27px" : "18px",
            paddingBottom: window.innerWidth >= 1080 ? "60px" : "40px",
            marginBottom: 0
        }

        this.setState({
            changeProductStyle
        });
    }

    handleTouchStart(event: any) {
        this.touching = true;
        this.startX = event.changedTouches[0].pageX + (this.navigationDrawerContainerRef.current.offsetLeft + this.navigationDrawerContainerRef.current.offsetWidth);
    }

    handleTouchMove(event: any) {
        if (this.touching == true) {
            // A left swipe over the modal should dismiss it
            // Distance gap should be big enough that it doesn't interfere with vertical scrolling if person has any horizontal movement
            const x = event.changedTouches[0].pageX + (this.navigationDrawerContainerRef.current.offsetLeft + this.navigationDrawerContainerRef.current.offsetWidth);
            const distance = (x - this.startX);

            if (distance < (-(window.innerWidth / 3))) {
                this.props.closeNavigationDrawer()
            }
        }
    }

    handleTouchEnd(event: any) {
        this.touching = false;
    }

    scrollContentToTop() {
        if (this.navigationDrawerRef.current != null) {
            this.navigationDrawerRef.current.scrollTop = 0;
        }
    }

    beginHold = (event) => {
        this.timer = 0;
        this.timeInterval = setInterval(() => {
            this.timer += 1;
            if(this.timer >= 3) {
                this.props.openSettingsModal(event);
                this.finishHold(event);
            }
        }, 1000);
    }

    finishHold = (event) => {
        clearInterval(this.timeInterval);
        this.timer = 0;
    }

    render() {
        let { codMode } = this.props;
        codMode = codMode === false ? null : codMode;

        return (
            <React.Fragment>
                <TranslateContext.Consumer>
                    {({ t }) => (
                        <React.Fragment>
                            <div ref={this.navigationDrawerContainerRef} className={this.state.containerClassName} style={this.props.navigationDrawerContainerStyle} onTouchStart={event => this.handleTouchStart(event)} onTouchMove={event => this.handleTouchMove(event)} onTouchEnd={event => this.handleTouchEnd(event)}>
                                <div ref={this.navigationDrawerRef} className={styles[`navigation-drawer`]}>
                                    {codMode == null &&  (
                                        <div className={styles[`navigation-drawer-links-top`]}>
                                            <Link to="/" className={styles[`navigation-drawer-link-top`]} onClick={event => this.props.closeNavigationDrawer()}>{t(`navigationDrawer.home`)}</Link>
                                        </div>
                                    )}
                                    <hr />
                                    {this.props.show == true &&
                                        <ChangeProductContent id={'Product-change-from-NavDrawer'} storeId={this.props.storeId} dataApiBaseUrl={this.props.dataApiBaseUrl} imageApiBaseUrl={this.props.imageApiBaseUrl} changeProduct={this.props.changeProduct} style={this.state.changeProductStyle} storeTerminalId={this.props.storeTerminalId} />
                                    }
                                    <hr />
                                    <div className={styles[`navigation-drawer-links`]}>
                                        <Link to="/privacy-policy" className={styles[`navigation-drawer-link`]} onClick={event => this.props.closeNavigationDrawer()}>{t(`navigationDrawer.privacy_policy`)}</Link>
                                    </div>
                                    {this.props.showThemeSwitcher == true &&
                                        <div className={styles[`theme-switcher`]} onClick={event => this.props.toggleTheme()}>
                                            {this.props.theme == "theme-light" &&
                                                <img src="/assets/icons/light_mode_black_24dp.svg" alt="" />
                                            }
                                            {this.props.theme == "theme-dark" &&
                                                <img src="/assets/icons/dark_mode_black_24dp.svg" alt="" />
                                            }
                                        </div>
                                    }
                                    {this.props.kioskMode == true && 
                                    <div className={styles["navigation-drawer-footer"]}>
                                        <div className={styles[`navigation-drawer-settings-button`]} 
                                            onMouseDown={(event) => this.beginHold(event)}
                                            onMouseUp={(event) => this.finishHold(event)}
                                            onTouchStart={(event) => this.beginHold(event)}
                                            onTouchEnd={(event) => this.finishHold(event)}>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </TranslateContext.Consumer>
            </React.Fragment>
        )
    }
}

export default NavigationDrawer;
