import React from "react";

export const Meta = () => (
    <React.Fragment>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Cache-Control" content="public, max-age=5552000" />
        <meta httpEquiv="Pragma" content="public, max-age=5552000" />
        <link href="https://fonts.googleapis.com/css?family=Roboto|Roboto:bold" rel="prefetch" />
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="prefetch"></link>       
        <link href="/assets/fonts/FeniceStd/FeniceStd_Regular_0.otf" rel="prefetch"></link>       
        <link href="/assets/fonts/FoundersGrotesk/FoundersGroteskText-Regular.otf" rel="prefetch"></link>       
    </React.Fragment>
);
