import React from "react";
import styles from "../styles/DarkenFilterOverlay.module.scss"

export const DarkenFilterOverlay = ({ show, onHide, style }) => {
    
    const hideOverlay = () => {
        onHide();
    }
    
    return (
        <React.Fragment>
            {show == true && <div className={styles[`darken-filter-overlay`]} style={style ? style : null} onClick={event => hideOverlay()}></div>}
        </React.Fragment>
    )
}