// json-api-repository.ts
// Author: Matthew Tinn
// Date: 20/01/2021
// API calls for the JSON API

export const jsonApiRepository = {
    buildProductDivisions: async (dataApiUrl: string, store_id: string, locale: string, route_of_customisation?: string) => {
        try {
            let getProductDivisionsUrl = `${dataApiUrl}get-product-divisions?store=${store_id}&locale=${locale}`;

            if (route_of_customisation != null && route_of_customisation != "") {
                getProductDivisionsUrl += `&roc=${route_of_customisation}`
            }

            const productDivisionsResponse = await fetch(getProductDivisionsUrl);
            const productDivisionsResult = await productDivisionsResponse.json();

            let divisions = productDivisionsResult.Result.Divisions;

            let divisionPromises = [];

            divisions.forEach(division => {
                let getProductsForDivisionUrl = `${dataApiUrl}get-products-by-division?store=${store_id}&locale=${locale}&div=${division.Name}`;

                if (route_of_customisation != null && route_of_customisation != "") {
                    getProductsForDivisionUrl += `&roc=${route_of_customisation}`
                }

                divisionPromises.push(fetch(getProductsForDivisionUrl));
            });

            const resolvedPromises = await Promise.all(divisionPromises);

            let resolvedProductsPromises = [];
            let resolvedProducts = [];

            resolvedPromises.forEach(resolvedPromise => {
                resolvedProductsPromises.push(resolvedPromise.json());
            })

            resolvedProducts = await Promise.all(resolvedProductsPromises);

            for (let i = 0; i < resolvedProducts.length; i++) {
                const products = resolvedProducts[i].Result.Products;

                products.forEach(product => {
                    product.Division = divisions[i].Name;
                });

                divisions[i][`Products`] = products;
            }

            return divisions;
        } catch (error) {
            return {
                success: false,
                error: error.message
            }
        }
    },

    getRoutesOfCustomisationForProduct: async (store_id: string, product_index: string, width: number, height: number, opt1?: string, recipe_data?: any, locale?: string) => {
        try {
            let url = `${process.env.REACT_APP_JSON_API_BASE_URL}get-roc?store=${store_id}&productIndex=${product_index}&width=${width}&height=${height}`;


            if (opt1 != null && opt1 != "") {
                url += `&opt1=${opt1}`;
            }

            if (locale != null && locale != "") {
                url += `&locale=${locale}`;
            }

            const requestBody = {
                recipeData: recipe_data ? recipe_data : null
            };

            const headers = {

            };

            const response = await fetch(url, {
                method: "PUT",
                mode: "cors",
                referrerPolicy: 'no-referrer',
                headers: headers,
                body: JSON.stringify(requestBody)
            });

            const result = await response.json();

            return result;
        } catch (error) {
            return {
                success: false,
                error: error.message
            }
        }
    },

    getProductDetails: async (store_id: string, product_index: string, route_of_customisation?: string, recipe_data?: any, locale?: string) => {
        try {
            let url = `${process.env.REACT_APP_JSON_API_BASE_URL}get-product-details?store=${store_id}&productIndex=${product_index}`;

            if (route_of_customisation != null && route_of_customisation != "") {
                url += `&roc=${route_of_customisation}`
            }

            if (locale != null && locale != "") {
                url += `&locale=${locale}`;
            }

            const requestBody = {
                recipeData: recipe_data ? recipe_data : null
            };

            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json"
            };

            const response = await fetch(url, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(requestBody)
            });

            const result = await response.json();

            return result;
        } catch (error) {
            return {
                success: false,
                error: error.message
            }
        }
    },

    getProductOptions: async (dataApiBaseUrl: string, store_id: string, product_index: string, recipe: any, locale?: string) => {
        try {
            let url = `${dataApiBaseUrl}get-product-options?store=${store_id}&productIndex=${product_index}`;

            if (locale != null && locale != "") {
                url += `&locale=${locale}`;
            }

            const response = await fetch(url, {
                method: "PUT",
                body: JSON.stringify(recipe)
            });

            const result = await response.json();

            return result.Result.Options;
        } catch (error) {
            return {
                success: false,
                error: error.message
            }
        }
    },

    getConfigureDetails: async (store_id: string, product_index: string, area_id: string, width: number, height: number, opt1?: string, recipe_data?: any, locale?: string) => {
        try {
            let url = `${process.env.REACT_APP_JSON_API_BASE_URL}get-configure-details?store=${store_id}&productIndex=${product_index}&area=${area_id}&width=${width}&height=${height}`;


            if (opt1 != null && opt1 != "") {
                url += `&opt1=${opt1}`;
            }

            if (locale != null && locale != "") {
                url += `&locale=${locale}`;
            }

            const requestBody = {
                recipeData: recipe_data ? recipe_data : null
            };

            const headers = {
                "Accept": "application/json",
                "Content-Type": "application/json"
            };

            const response = await fetch(url, {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(requestBody)
            });

            const result = await response.json();

            return result;
        } catch (error) {
            return {
                success: false,
                error: error.message
            }
        }
    },

    getConfigureDetailsWithRecipe: async (dataurl: string, store_id: string, area_id: string, width: number, height: number, recipe: any, locale: string) => {
        try {
            let url = `${dataurl}get-configure-details?store=${store_id}&area=${area_id}&width=${width}&height=${height}&locale=${locale}`;

            const response = await fetch(url, {
                method: "PUT",
                body: JSON.stringify(recipe)
            });

            const result = await response.json();

            return result;
        } catch (error) {
            return {
                success: false,
                error: error.message
            }
        }
    }
}