import React from "react";
import { withRouter } from "react-router-dom";
import pony from "../assets/images/error-page-pony.png";
import TranslateContext from "../providers/TranslateContext";
import styles from "../styles/ErrorBoundary.module.scss";

class ErrorBoundary extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //   logErrorToMyService(error, errorInfo);
    }

    resetError = () => {
        this.setState({ hasError: false });
        window.location.assign("/");
    };

    render() {
        if (this.state.hasError) {
            return (
                <React.Fragment>
                    <TranslateContext.Consumer>
                        {({ t }) => (
                            <React.Fragment>
                                <main className={styles.errorContainer}>
                                    <img className={styles.errorPagePonyImage} src={pony} alt="pony"/>
                                    <h1>{t(`errorPage.heading`)}</h1>
                                    <h2>{t(`errorPage.subheading`)}</h2>
                                    <div>
                                        <a onClick={this.resetError}>
                                            {t(`errorPage.message`)}
                                        </a>
                                    </div>
                                </main>
                            </React.Fragment>
                        )}
                    </TranslateContext.Consumer>
                </React.Fragment>
            );
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
