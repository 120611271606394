import React from "react";
import styles from "../styles/Screensaver.module.scss"

export const Screensaver = ({ videoUrl }) => {

    return (
        <React.Fragment>
            <div className={styles[`screensaver`]}>
                <video src={`${process.env.REACT_APP_STORE_S3_BUCKET}${videoUrl}`} autoPlay={true} loop={true} muted={true}></video>
            </div>
        </React.Fragment>
    )
}