import React from "react";
import TranslateContext from "../providers/TranslateContext";
import styles from "../styles/LocalisationModal.module.scss"
import EventEmitter from "../helpers/events";

class LocalisationModal extends React.Component<any, any> {
    constructor(props) {
        super(props);
    }

    static contextType = TranslateContext;

    changeCountry(locale) {        
        this.context.changeLocale(locale);
        EventEmitter.dispatch("localeChange", { locale: locale });
        setTimeout(() => {
            this.props.hideLocalisationModal();
        }, 500);
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles[`localisation-modal-container`]}>
                    <div className={styles[`localisation-modal`]}>
                        {this.context.availableLocales.map((locale, index) => {
                            return (
                                <div key={index} className={styles[`localisation-modal-option`]} onClick={event => this.changeCountry(locale.Code)}>
                                    <div className={styles[`localisation-modal-option-selected`]}>
                                        {locale.Code == this.context.locale &&
                                            <img src="/assets/icons/tick.svg" alt="tick" className={styles[`localisation-modal-option-selected-tick`]}/>
                                        }
                                    </div>
                                    <div className={styles[`localisation-modal-option-name`]}>
                                        {locale.DisplayName}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </React.Fragment>
        )
    }

}

export default LocalisationModal;