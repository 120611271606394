import React from "react";
import styles from '../styles/Loader.module.scss';


class LoaderForBlurEffect extends React.Component<any, any> {

    timeout: any;

    constructor(props) {
        super(props);
        this.state = { hidden: true }
    }

    componentDidMount() {
      
        this.timeout = setTimeout(() => {
            this.setState({ hidden: false })
        }, this.props.timeout);
    }
    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {

        if (this.state.hidden) {
            return null;
        }
        else {
            return (
                <React.Fragment>
                    <div className={styles[`loader-container`]}>
                        <div className={styles[`loader`]} />
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default LoaderForBlurEffect;