// NavigationDrawerShield
// Overlays the whitespace to left of navbar to hide navigation drawer on desktop

import React from "react";
import styles from "../../../styles/NavigationDrawer.module.scss"

export const NavigationDrawerShield = ({style}) => {
    return (
        <div className={styles[`navigation-drawer-shield`]} style={style}></div>
    );
};