import React from "react";
import { Link, withRouter } from "react-router-dom";
import EventEmitter from "../../helpers/events";
import TranslateContext from "../../providers/TranslateContext";
import styles from '../../styles/Navbar.module.scss';
import { DarkenFilterOverlay } from "../DarkenFilterOverlay";
import NavigationDrawer from "../drawers/navigation/NavigationDrawer";
import { NavigationDrawerShield } from "../drawers/navigation/NavigationDrawerShield";
import LocalisationModal from "../LocalisationModal";
import { NavbarCartItemsCounter } from "./navbar-cart-items-counter";
import HiddenSettingsModal from "./HiddenSettingsModal";

class Navbar extends React.Component<any, any> {

    navbarRef = null;
    dismissRef = null;
    static contextType = TranslateContext;

    constructor(props) {
        super(props);
        this.navbarRef = React.createRef();
        this.dismissRef = React.createRef();
        this.state = {
            showNavigationDrawer: false,
            navigationDrawerHeight: 0,
            darkenOverlayStyle: {},
            navigationDrawerShieldStyle: {},
            navigationDrawerContainerStyle: {},
            showHiddenSettingsModal: false
        }
    }

    componentDidMount() {

        this.setChildComponentStyleOverrides();
        window.addEventListener("resize", this.setChildComponentStyleOverrides);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setChildComponentStyleOverrides);
    }

    handleLocalisationIconClick = () => {
        EventEmitter.dispatch("navbarClicked", {});

        if (this.state.showNavigationDrawer == true) {
            this.hideNavigationDrawer();
        }

        if (this.props.localisationModalVisible == false) {
            this.props.showLocalisationModal();
        }
        else {
            this.props.hideLocalisationModal();
        }
    }

    clickDismissLocalisationModalEl = (event) => {
        if (event.target != this.dismissRef.current) {
            event.stopPropagation();
            return;
        }

        this.props.hideLocalisationModal();
    }

    toggleNavigationDrawer = async () => {
        if (this.state.showNavigationDrawer == false) {
            EventEmitter.dispatch("navbarClicked", {});
            this.showNavigationDrawer();
        }
        else {
            this.hideNavigationDrawer()
        }
    }

    showNavigationDrawer = () => {
        this.props.hideLocalisationModal();
        this.setState({
            showNavigationDrawer: true
        })
    }

    hideNavigationDrawer = () => {
        this.setState({
            showNavigationDrawer: false
        })
    }

    setChildComponentStyleOverrides = () => {
        if (this.navbarRef != null) {
            const navbar = this.navbarRef.current;
            const windowHeight = window.innerHeight;

            const navigationDrawerHeight = windowHeight - navbar.offsetHeight;

            const navigationDrawerContainerStyle = {
                height: `${navigationDrawerHeight}px`
            }

            const darkenOverlayStyle = {
                top: `${navbar.offsetHeight}px`,
                height: `${navigationDrawerHeight}px`
            }

            const navigationDrawerShieldStyle = {
                height: `${navigationDrawerHeight}px`
            };

            this.setState({
                darkenOverlayStyle,
                navigationDrawerShieldStyle,
                navigationDrawerContainerStyle: navigationDrawerContainerStyle
            });
        }
    }

    changeProduct = (product) => {
        this.hideNavigationDrawer();
        this.props.history.push(`/product-designer/${product.Index}?mode=product`);
    }

    handleTitleClick = async () => {

        const location = this.props.location;
        const path = location.pathname;

        //Must reset store when in CoD-mode-only stores
        const isCoDMode = this.props.storeJSON && this.props.storeJSON.CoDMode;

        if(isCoDMode != null && isCoDMode === true) {
            await this.props.getStoreMap(this.props.store, "navbar");
        }
        else {
            if (path == "/") {
                window.location.reload();
            }
            else {
                this.props.history.push('/');
            }
        }
    }

    openSettingsModal = () => {
        this.hideNavigationDrawer();
        this.setState({showHiddenSettingsModal: true});
    }

    closeSettingsModal = () => {
        this.setState({showHiddenSettingsModal: false});
    }

    render() {
        const isCoDMode = this.props.storeJSON && this.props.storeJSON.CoDMode;
        return (
            <React.Fragment>
                <div ref={this.navbarRef} className={styles[`navbar`]}>
                    <div className={styles[`navbar-hamburger_container`]} onClick={event => { this.toggleNavigationDrawer() }}>
                        {this.state.showNavigationDrawer == false &&
                            <img className={styles[`navbar-item`]} src="/assets/icons/burger-menu_48x48.svg" alt="" />
                        }
                        {this.state.showNavigationDrawer == true &&
                            <img className={styles[`navbar-item`]} src="/assets/icons/close-white-32px.svg" alt="" />
                        }
                    </div>
                    <div className={styles[`navbar-logo_container`]} onClick={event => this.hideNavigationDrawer()}>
                        <span className={styles[`navbar-logo`]} onClick={this.handleTitleClick}>{this.context.t(`navbar.create_your_own`)}</span>
                    </div>
                    {this.props.disableLangChange == false && 
                        <div className={styles[`navbar-localisation_container`]}>
                            <img className={styles[`navbar-item`]} src="/assets/icons/change-lang_48x48.svg" alt="" onClick={(event) => { this.handleLocalisationIconClick() }} />
                            {this.props.localisationModalVisible &&
                                <LocalisationModal hideLocalisationModal={this.props.hideLocalisationModal} storeJSON={this.props.storeJSON} store={this.props.store} />
                            }
                        </div>
                    }
                    <div className={styles[`navbar-cart_container`]} onClick={event => this.hideNavigationDrawer()}>
                        <Link to="/basket">
                            <img className={styles[`navbar-item`]} src="/assets/icons/basket_48x48.svg" alt="" />
                            <NavbarCartItemsCounter />
                        </Link>
                    </div>

                    <DarkenFilterOverlay show={this.state.showNavigationDrawer} onHide={this.hideNavigationDrawer} style={this.state.darkenOverlayStyle} />
                    <NavigationDrawerShield style={this.state.navigationDrawerShieldStyle} />
                    <NavigationDrawer 
                        storeId={this.props.storeId} 
                        dataApiBaseUrl={this.props.yrApiUrls.dataApiBaseUrl} 
                        imageApiBaseUrl={this.props.yrApiUrls.imageApiBaseUrl}
                        show={this.state.showNavigationDrawer}
                        changeProduct={this.changeProduct}
                        closeNavigationDrawer={this.hideNavigationDrawer}
                        navigationDrawerContainerStyle={this.state.navigationDrawerContainerStyle}
                        openSettingsModal={this.openSettingsModal}
                        kioskMode={this.props.kioskMode}
                        theme={this.props.theme}
                        showThemeSwitcher={this.props.showThemeSwitcher}
                        toggleTheme={this.props.toggleTheme}
                        codMode={isCoDMode}
                        storeTerminalId={this.props.storeTerminalId}
                    />
                    {this.state.showHiddenSettingsModal == true && 
                        <HiddenSettingsModal
                            closeModal={this.closeSettingsModal}
                            quickAdd={this.props.quickAdd}
                            disableStorePickup={this.props.disableStorePickup}
                            updateDisableStorePickupLocalStorageItem={this.props.updateDisableStorePickupLocalStorageItem}
                            updateQuickAddLocalStorageItem={this.props.updateLocalStorageItem}
                        />
                    }
                </div>
                {this.props.localisationModalVisible &&
                    <div ref={this.dismissRef} className={styles[`dismiss-localisation-modal-element`]} onClick={event => this.clickDismissLocalisationModalEl(event)}></div>
                }
            </React.Fragment>
        )
    }
}

export default withRouter(Navbar);