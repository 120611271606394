export const getYrHotspotCoords = (hotspotArea: any) => {
    const hotspotYrCoords = hotspotArea.Position.split("|");
    const hotspotYrCoordsCenter = hotspotYrCoords[0].split(",");
    const hotspotYrCoordsSize = hotspotYrCoords[1].split(",");

    return {
        x: parseFloat(hotspotYrCoordsCenter[0]),
        y: parseFloat(hotspotYrCoordsCenter[1]),
        width: parseFloat(hotspotYrCoordsSize[0]),
        height: parseFloat(hotspotYrCoordsSize[1])
    }
}
export const getYrHotspotCoordsForText = (hotspot: any) => {

    const position = hotspot.position;
    const coords = position.split(",");
    return { x: parseFloat(coords[0]), y: parseFloat(coords[1]) };
}
export const getXCoord = (x, containerWidth, hotspotWidth) => {

    return (x * containerWidth + (containerWidth / 2)) - (hotspotWidth / 2);
}

export const getYCoord = (y, containerHeight, hotspotHeight) => {


    return (y * containerHeight + (containerHeight / 2)) - (hotspotHeight / 2);
}
