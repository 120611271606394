import { getRecipesFromCart } from "./get-recipes-from-cart";
import { updateRecipeWithDisplayNames } from "./update-recipe-with-customisation-names";

export const updateCartRecipesWithCustomisationNames = async (options) => {

    const { cart, dataApiBaseUrl, storeId, locale } = options;
    const recipes = getRecipesFromCart(cart.cart_items);
    const updatedRecipePromises = [];

    for (let recipe of recipes) {
        const options = { dataApiBaseUrl, storeId, recipe, locale }
        const updatedRecipe = updateRecipeWithDisplayNames(options);
        updatedRecipePromises.push(updatedRecipe);
    }
    const updatedRecipes = await Promise.all([...updatedRecipePromises]);

    cart.cart_items.forEach((item, index) => {
        item.product.productRecipe = updatedRecipes[index];
    });

    return cart;

}