export const getCookie = (cname) => {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
//https://stackoverflow.com/questions/2144386/how-to-delete-a-cookie
export const deleteCookie = (sKey, sPath, sDomain) => {

    document.cookie = encodeURIComponent(sKey) +
        "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" +
        (sDomain ? "; domain=" + sDomain : "") +
        (sPath ? "; path=" + sPath : "");
}
//https://stackoverflow.com/questions/179355/clearing-all-cookies-with-javascript
export const deleteAllCookies = () => {
   var cookies = document.cookie.split("; ");
   for (var c = 0; c < cookies.length; c++) {
       var d = window.location.hostname.split(".");
       while (d.length > 0) {
           var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
           var p = location.pathname.split('/');
           document.cookie = cookieBase + '/';
           while (p.length > 0) {
               document.cookie = cookieBase + p.join('/');
               p.pop();
           };
           d.shift();
       }
   }
}