
export const areaHelpers = {

    getRecipeCustomisationAreas: (recipe) => {

        let areas = [];

        for (let area of recipe.customisation) {
            areas.push(area.area);
        }

        return areas;
    },
    getAllAreasOfCurrentRoc: (currentROC: any) => {
       
        const views = [...currentROC.View];       
        const areas:any[] = [];
        for (let view of views) {
            const area = [...view.Area];
            areas.push(...area);
        }
        return areas;
    }
}