export const getQueryParametersFromUrl = (url: string) => {
    let queryParameters: any = {};
    
    const splitUrl = url.split("?");

    if (splitUrl.length > 1) {
        const queryParametersString = splitUrl[1];
        const splitQueryParametersStrings = queryParametersString.split("&");
       

        splitQueryParametersStrings.forEach(queryParameterString => {
            const splitQueryParameterString = queryParameterString.split("=");
            const key = splitQueryParameterString[0];
            const value = splitQueryParameterString[1];

            queryParameters[key] = value;
        });
    }

    return queryParameters;
}