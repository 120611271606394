import React from "react";
import { withRouter } from "react-router-dom";
import { loadDivisions } from "../../../helpers/load-divisions";
import TranslateContext from "../../../providers/TranslateContext";
import styles from "../../../styles/ChangeProductModal.module.scss";
import ChangeProductModalThumbnail from "./ChangeProductModalThumbnail";
import { ChangeProductSkeletonLoader } from "./ChangeProductSkeletonLoader";
import { createMarkup } from "../../../helpers/locale-text-helpers";

class ChangeProductContent extends React.Component<any, any> {
    constructor(props) {
        super(props);
        this.state = {
            contentStyle: null,
            loading: true,
            divisions: []
        };
    }

    static contextType = TranslateContext;

    async componentDidMount() {
        this.updateContentHeight();
        window.addEventListener("resize", () => {
            this.updateContentHeight();
        });
        await this.loadDivisions();
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousState.contentStyle != this.state.contentStyle && this.props.style != null) {
            this.updateContentHeight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateContentHeight);
    }

    updateContentHeight() {
        let contentStyle = {};

        if (this.props.style != null) {
            contentStyle = this.props.style;
        }

        this.setState({ contentStyle });
    }

    async loadDivisions() {
        try {
            const divisions = await loadDivisions(
                this.props.dataApiBaseUrl,
                this.props.storeId,
                this.context.locale,
                this.props.routeOfCustomisation ? this.props.routeOfCustomisation : null
            );

            this.setState({
                divisions,
                loading: false
            });
        } catch (error) {
            this.props.history.push("/error");
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={styles[`change-product-content`]} style={this.state.contentStyle}>
                    {this.state.divisions != null &&
                        Array.isArray(this.state.divisions) &&
                        this.state.divisions.map((division, index) => {
                            const divisionDisplayName: string = division.DisplayName;
                            return (
                                <div key={index} className={styles[`change-product-division`]}>
                                    <div className={styles[`change-product-division-title`]} 
                                    dangerouslySetInnerHTML={createMarkup(divisionDisplayName)} />
                                    <div className={styles[`change-product-division-products`]}>
                                        {division.Products.map((product, index) => {
                                            return (
                                                <ChangeProductModalThumbnail
                                                    id={this.props.id}
                                                    key={index}
                                                    product={product}
                                                    changeProduct={this.props.changeProduct}
                                                    imageApiBaseUrl={this.props.imageApiBaseUrl}
                                                    storeTerminalId={this.props.storeTerminalId}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    {this.state.loading == true && (
                        <ChangeProductSkeletonLoader />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ChangeProductContent);
