import { formatDate } from "../format-yyyy-mm-dd-hhmmss";
import { localStorageItemMap } from "../local-storage-parameter-map";

export const sendEvent = (category: string, action: string, label: string, storeID: string, optional?: any) => {
    const env = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT;
    const iFrameLocalStorageItem = localStorage.getItem(localStorageItemMap.iFrame);

    if( iFrameLocalStorageItem != null && iFrameLocalStorageItem === "true") {
        return;
    }

    if (env == 'cn-prod') {
        //@ts-ignore
        const _hmt = window._hmt;
        _hmt.push(['_trackEvent', category, action, label]);
    }
    else {
        //@ts-ignore
        const dataLayer: any = window.dataLayer || [];
        dataLayer.push({
            event: category,
            eventAction: action,
            eventLabel: label,
            storeID,
            timestamp: formatDate(new Date()),
            ...(optional && { ...optional })
        });
    }
}