import { jsonApiRepository } from "../repositories/json-api-repository";

export const loadDivisions = async (dataApiBaseUrl:string, storeId:string, locale:string, routeOfCustomisation:string) => {

    try {       
        const divisions = await jsonApiRepository.buildProductDivisions(dataApiBaseUrl, storeId, locale, routeOfCustomisation);
        return divisions
    }
    catch (error) {
        console.log(error)
    }
}