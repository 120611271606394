import React, { useEffect } from "react";
import NProgress from "nprogress";
import 'nprogress/nprogress.css';

export const ProgressBar = () => {

    useEffect(() => {      
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return <React.Fragment></React.Fragment>;
}

export default ProgressBar;