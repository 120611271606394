/**
 * Function that returns the content needed by the 'dangerouslySetInnerHTML' JSX attribute,
 * which decodes HTML Entities.
 * @param s the string content of the markup
 * @see https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
 * @see https://shripadk.github.io/react/docs/jsx-gotchas.html#html-entities
 * @returns 
 */
export const createMarkup = (s: string) => {
    return {__html: s};
}